import React from "react";
import { AiOutlineCloudUpload, AiOutlineUpload } from "react-icons/ai";

interface EmptyStateProps {
    isCollectionOwner: boolean;
    onUpload: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ isCollectionOwner, onUpload }) => (
    <div className="flex flex-col items-center justify-center h-64 bg-gray-100 rounded-lg">
        <AiOutlineCloudUpload className="text-6xl text-gray-400 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-700 mb-2">No videos yet</h2>
        <p className="text-gray-500 mb-4">Upload your first video to get started!</p>
        {isCollectionOwner && (
            <button className="btn btn-primary text-white" onClick={onUpload}>
                <AiOutlineUpload size={18} className="mr-2" />
                Upload Video
            </button>
        )}
    </div>
);

export default EmptyState;
