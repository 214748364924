import React from "react";
import GridBackground from "../../components/Welcome/GridBackground";
import Footer from "../../components/Footer";

const QuickGen = () => {
    return (
        <div className="min-h-screen bg-white">
            <div className="relative overflow-hidden bg-gradient-to-b from-white to-gray-50 -mt-9">
                <GridBackground gridOpacity={0.5} gridSize={40} gridColor="#CBD5E1" />
                <div className="container mx-auto px-6 pt-32 pb-24 relative">
                    <div className="max-w-4xl mx-auto text-center">
                        <div className="mb-8">
                            <span className="px-4 py-2 rounded-full bg-purple-100 text-purple-600 text-sm font-medium">
                                Coming Soon
                            </span>
                        </div>
                        <h1 className="text-5xl md:text-7xl font-bold leading-tight mb-8">
                            Transform Raw Footage into{" "}
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
                                Masterpieces
                            </span>
                            {" "}with AI
                        </h1>
                        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                            The future of video creation is here. Upload your footage, describe your vision, 
                            and watch as AI crafts your perfect story. From documentaries to social content, 
                            create professional videos in minutes, not months.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default QuickGen;
