import React, { useState } from 'react';
import { FiClock, FiCheck, FiX } from 'react-icons/fi';

interface TimestampControlProps {
    startTime: number | null;
    endTime: number | null;
    currentTime: number;
    onSetTime: () => void;
    onClear: () => void;
    onTimeUpdate: (start: number | null, end: number | null) => void;
}

const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const TimestampControl: React.FC<TimestampControlProps> = ({
    startTime,
    endTime,
    currentTime,
    onSetTime,
    onClear,
    onTimeUpdate,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [startInput, setStartInput] = useState('');
    const [endInput, setEndInput] = useState('');

    const parseTimeInput = (input: string): number | null => {
        const parts = input.split(':').map(part => parseInt(part, 10));
        if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
            return parts[0] * 60 + parts[1];
        }
        return null;
    };

    const handleSave = () => {
        const newStart = parseTimeInput(startInput);
        const newEnd = parseTimeInput(endInput);
        
        if (newStart !== null) {
            onTimeUpdate(newStart, newEnd || newStart);
            setIsEditing(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleTimeSubmit = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSave();
        } else if (e.key === 'Escape') {
            handleCancel();
        }
    };

    return (
        <div className="flex items-center gap-2">
            {!startTime ? (
                <button
                    type="button"
                    onClick={onSetTime}
                    className="inline-flex items-center gap-2 px-3 py-1.5 bg-purple-50 hover:bg-purple-100 
                            text-purple-700 rounded-md border border-purple-200 transition-colors"
                >
                    <FiClock className="h-4 w-4" />
                    <span className="font-medium">Comment at {formatTime(currentTime)}</span>
                </button>
            ) : (
                <div className="flex flex-col w-full gap-2">
                    <div 
                        className="flex flex-nowrap items-center gap-2 px-3 py-1.5 bg-purple-50 rounded-md border border-purple-200"
                    >
                        <FiClock className="h-4 w-4 text-purple-600 flex-shrink-0" />
                        {!isEditing ? (
                            <div className="flex items-center min-w-0">
                                <div 
                                    className="cursor-pointer hover:bg-purple-100 px-2 py-1 rounded truncate"
                                    onClick={() => {
                                        setStartInput(formatTime(startTime));
                                        setEndInput(endTime ? formatTime(endTime) : formatTime(startTime));
                                        setIsEditing(true);
                                    }}
                                >
                                    <span className="font-medium text-gray-900">{formatTime(startTime)}</span>
                                    {endTime && endTime !== startTime && (
                                        <>
                                            <span className="text-gray-400 mx-1">to</span>
                                            <span className="font-medium text-gray-900">{formatTime(endTime)}</span>
                                        </>
                                    )}
                                </div>
                                <button
                                    type="button"
                                    onClick={onClear}
                                    className="ml-2 px-2 py-0.5 text-sm text-gray-500 hover:text-red-500 
                                            hover:bg-red-50 rounded transition-colors"
                                >
                                    Clear
                                </button>
                            </div>
                        ) : (
                            <div className="flex flex-col w-full gap-2">
                                <div className="flex flex-wrap items-start gap-2">
                                    <div className="flex flex-wrap items-end gap-2">
                                        <div className="flex flex-col min-w-[100px]">
                                            <label className="text-xs text-gray-500">Start (mm:ss)</label>
                                            <input
                                                type="text"
                                                value={startInput}
                                                onChange={(e) => setStartInput(e.target.value)}
                                                onKeyDown={handleTimeSubmit}
                                                className="w-full bg-white rounded px-2 py-1 border border-gray-200"
                                                placeholder="1:30"
                                            />
                                        </div>
                                        <div className="flex flex-col min-w-[100px]">
                                            <label className="text-xs text-gray-500">End (mm:ss)</label>
                                            <input
                                                type="text"
                                                value={endInput}
                                                onChange={(e) => setEndInput(e.target.value)}
                                                onKeyDown={handleTimeSubmit}
                                                className="w-full bg-white rounded px-2 py-1 border border-gray-200"
                                                placeholder="2:00"
                                            />
                                        </div>
                                        <div className="flex gap-1">
                                            <button
                                                onClick={handleSave}
                                                className="p-1.5 text-green-600 hover:bg-green-50 rounded-full"
                                                title="Save timestamps"
                                            >
                                                <FiCheck className="w-4 h-4" />
                                            </button>
                                            <button
                                                onClick={handleCancel}
                                                className="p-1.5 text-gray-500 hover:bg-gray-100 rounded-full"
                                                title="Cancel"
                                            >
                                                <FiX className="w-4 h-4" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimestampControl;