import React, { useState, useRef, useEffect } from "react";
import {
    AiOutlinePlayCircle,
    AiOutlineMessage,
    AiOutlineMore,
    AiOutlineWarning,
    AiOutlineEdit,
    AiOutlineDelete,
} from "react-icons/ai";
import { Comment } from "./types";
import { formatTime } from "./utils";
import { AddComment } from "./AddComment";
import Modal from "../Modal";
import { Dropdown } from "../Dropdown";
import axios from "axios";
import { formatRelativeTime } from "../utils/date";

interface CommentItemProps {
    comment: Comment;
    indexId: string;
    onSeek: (time: number) => void;
    onCommentUpdate: () => void;
    videoId: string;
    isPlaying: boolean;
    setIsPlaying: (playing: boolean) => void;
    currentTime: number;
    currentUserEmail: string;
    fetchReplies: (commentId: string) => Promise<Comment | null>;
    depth: number;
    isLoadingReplies?: boolean;
    readOnly: boolean;
    shareToken?: string;
    selectedCommentId: string | null;
    setSelectedCommentId: (id: string) => void;
}

export const CommentItem: React.FC<CommentItemProps> = ({
    comment,
    indexId,
    onSeek,
    onCommentUpdate,
    videoId,
    isPlaying,
    setIsPlaying,
    currentTime,
    currentUserEmail,
    fetchReplies,
    depth,
    isLoadingReplies,
    readOnly,
    shareToken,
    selectedCommentId,
    setSelectedCommentId,
}) => {
    const [showReplies, setShowReplies] = useState(false);
    const [isReplying, setIsReplying] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loadedReplies, setLoadedReplies] = useState<Comment[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeletingComment, setIsDeletingComment] = useState(false);
    const maxDepth = 3;

    const commentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedCommentId === comment.id && commentRef.current) {
            commentRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [selectedCommentId, comment.id]);

    const isSelected = selectedCommentId === comment.id;

    const getHeaders = () => {
        if (shareToken) {
            return { "X-Share-Token": shareToken };
        }
        const token = localStorage.getItem("token");
        return { Authorization: `Bearer ${token}` };
    };

    const handleDelete = async () => {
        setIsDeletingComment(true);
        try {
            await axios.delete(
                `${process.env.REACT_APP_API}/api/quick-review/comments/${videoId}/${comment.id}`,
                { headers: getHeaders() }
            );
            onCommentUpdate();
            setShowDeleteModal(false);
        } catch (error) {
            console.error("Error deleting comment:", error);
            alert("Failed to delete comment. Please try again.");
        } finally {
            setIsDeletingComment(false);
        }
    };

    const handleToggleReplies = async () => {
        if (!showReplies && comment.reply_count > 0) {
            const repliesData = await fetchReplies(comment.id);
            if (repliesData) {
                setLoadedReplies(repliesData.replies || []);
            }
        }
        setShowReplies(!showReplies);
    };

    const handleCommentSuccess = async () => {
        setIsReplying(false);
        setIsEditing(false);

        if (showReplies) {
            const repliesData = await fetchReplies(comment.id);
            if (repliesData) {
                setLoadedReplies(repliesData.replies || []);
            }
        }

        onCommentUpdate();
    };

    const dropdownItems = [
        {
            label: "Edit",
            icon: <AiOutlineEdit className="text-lg" />,
            onClick: () => setIsEditing(true),
            className: "text-gray-700",
        },
        {
            label: "Delete",
            icon: <AiOutlineDelete className="text-lg" />,
            onClick: () => setShowDeleteModal(true),
            className: "text-error",
        },
    ];

    return (
        <div
            ref={commentRef}
            data-comment-id={comment.id}
            className={`
                bg-base-200 p-3 rounded-lg 
                ${depth > 0 ? "ml-4" : ""} 
                ${isSelected ? "ring-1 ring-purple-500 bg-purple-50/50" : "hover:bg-base-300"}
                transition-all duration-200
            `}
            onClick={() => {
                setSelectedCommentId(comment.id);
                onSeek(comment.start_time);
                setIsPlaying(true);
            }}
        >
            <div className="flex items-center justify-between gap-2 text-sm">
                <div className="flex gap-2">
                    <span className="font-medium">
                        {comment.first_name} {comment.last_name}
                    </span>
                    <span className="opacity-50">•</span>
                    <span className="opacity-50">
                        {formatRelativeTime(new Date(comment.created_at))}
                        {comment.is_edited && " (edited)"}
                    </span>
                </div>
                {comment.user_email === currentUserEmail && (
                    <Dropdown
                        trigger={<AiOutlineMore className="text-lg" />}
                        items={dropdownItems}
                        align="right"
                        triggerClassName="btn btn-ghost btn-xs rounded-full"
                        menuClassName="w-44"
                        itemClassName="text-sm"
                    />
                )}
            </div>

            {isEditing ? (
                <AddComment
                    videoId={videoId}
                    indexId={indexId}
                    currentTime={currentTime}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    onCommentAdded={handleCommentSuccess}
                    initialContent={comment.content}
                    commentId={comment.id}
                    isEditing={true}
                    onCancel={() => setIsEditing(false)}
                    shareToken={shareToken}
                    currentUserEmail={currentUserEmail}
                />
            ) : (
                <p className="mt-2 mb-2">
                    <button
                        className="btn btn-ghost btn-xs bg-purple-100 hover:bg-purple-200"
                        onClick={e => {
                            e.stopPropagation();
                            setSelectedCommentId(comment.id);
                            onSeek(comment.start_time);
                            setIsPlaying(true);
                        }}
                    >
                        <AiOutlinePlayCircle />
                        <>
                            <p>{formatTime(comment.start_time)}</p>
                            {comment.end_time !== comment.start_time && (
                                <p>- {formatTime(comment.end_time)}</p>
                            )}
                        </>
                    </button>

                    {comment.content}
                </p>
            )}

            <div className="flex items-center gap-2 text-xs">
                {depth < maxDepth && !readOnly && (
                    <button
                        className="btn btn-ghost btn-xs"
                        onClick={e => {
                            e.stopPropagation();
                            setIsReplying(!isReplying);
                        }}
                    >
                        <AiOutlineMessage /> Reply
                    </button>
                )}
                {comment.reply_count > 0 && (
                    <button
                        className="btn btn-ghost btn-xs"
                        onClick={e => {
                            e.stopPropagation();
                            handleToggleReplies();
                        }}
                        disabled={isLoadingReplies}
                    >
                        {showReplies ? "Hide" : "Show"} {comment.reply_count} replies
                    </button>
                )}
            </div>

            {isReplying && (
                <div className="mt-2">
                    <AddComment
                        videoId={videoId}
                        indexId={indexId}
                        currentTime={currentTime}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        onCommentAdded={handleCommentSuccess}
                        parentComment={comment}
                        onCancel={() => setIsReplying(false)}
                        shareToken={shareToken}
                        currentUserEmail={currentUserEmail}
                    />
                </div>
            )}

            {showReplies && loadedReplies.length > 0 && (
                <div className="space-y-2 mt-2">
                    {isLoadingReplies ? (
                        <div className="flex justify-center py-2">
                            <div className="loading loading-spinner loading-sm text-primary"></div>
                        </div>
                    ) : (
                        loadedReplies.map(reply => (
                            <CommentItem
                                key={reply.id}
                                comment={reply}
                                indexId={indexId}
                                onSeek={onSeek}
                                onCommentUpdate={handleCommentSuccess}
                                videoId={videoId}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                                currentTime={currentTime}
                                currentUserEmail={currentUserEmail}
                                fetchReplies={fetchReplies}
                                depth={depth + 1}
                                readOnly={readOnly}
                                shareToken={shareToken}
                                selectedCommentId={selectedCommentId}
                                setSelectedCommentId={setSelectedCommentId}
                            />
                        ))
                    )}
                </div>
            )}

            <Modal
                isOpen={showDeleteModal}
                onClose={() => !isDeletingComment && setShowDeleteModal(false)}
                title="Confirm Delete"
            >
                <div className="flex flex-col items-center gap-4 p-6">
                    <AiOutlineWarning className="text-6xl text-yellow-500" />
                    <h2 className="text-xl font-semibold text-center">
                        Are you sure you want to delete this comment?
                    </h2>
                    <p className="text-gray-600 text-center">This action cannot be undone.</p>
                    <div className="flex justify-center gap-4 mt-4">
                        <button
                            className="btn btn-ghost"
                            onClick={() => setShowDeleteModal(false)}
                            disabled={isDeletingComment}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn bg-red-500 hover:bg-red-600 text-white"
                            onClick={handleDelete}
                            disabled={isDeletingComment}
                        >
                            {isDeletingComment ? (
                                <>
                                    <span className="loading loading-spinner loading-sm"></span>
                                    Deleting...
                                </>
                            ) : (
                                "Delete"
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
