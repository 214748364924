import React from "react";

interface GridBackgroundProps {
    gridOpacity?: number;
    gridSize?: number;
    gridColor?: string;
}

const GridBackground: React.FC<GridBackgroundProps> = ({
    gridOpacity = 0.3,
    gridSize = 30,
    gridColor = "#CBD5E1",
}) => {
    return (
        <div className="absolute inset-0">
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='${gridSize}' height='${gridSize}' viewBox='0 0 ${gridSize} ${gridSize}' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M ${gridSize} 0 L ${gridSize} ${gridSize} M 0 ${gridSize} L ${gridSize} ${gridSize}' stroke='${gridColor.replace("#", "%23")}' stroke-width='1'/%3E%3C/svg%3E")`,
                    opacity: gridOpacity,
                }}
            />
        </div>
    );
};

export default GridBackground;
