import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

interface VideoPlayerWithTimestampsProps {
    url: string;
    start: number;
    end: number;
    subtitleUrl?: string;
}

const SearchVideoPlayer: React.FC<VideoPlayerWithTimestampsProps> = ({
    url,
    start,
    end,
    subtitleUrl,
}) => {
    const playerRef = useRef<ReactPlayer>(null);
    const [playing, setPlaying] = useState<boolean>(false);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (ready && url && playerRef.current) {
            playerRef.current.seekTo(start, "seconds");
            setPlaying(true);
        }
    }, [ready, url, start]);

    const handleProgress = (state: { playedSeconds: number }) => {
        if (state.playedSeconds >= end && playing) {
            // setPlaying(false);
            playerRef.current?.seekTo(start, "seconds");
        }
    };

    return (
        <ReactPlayer
            ref={playerRef}
            url={url}
            playing={playing}
            onProgress={handleProgress}
            controls
            width="100%"
            height="100%"
            config={{
                file: {
                    attributes: {
                        crossOrigin: "anonymous",
                    },
                    tracks: subtitleUrl
                        ? [
                              {
                                  kind: "subtitles",
                                  src: subtitleUrl,
                                  srcLang: "en",
                                  label: "English",
                                  default: true,
                              },
                          ]
                        : [],
                },
            }}
            onReady={() => {
                setReady(true);
            }}
        />
    );
};

export default SearchVideoPlayer;