import React from "react";

const GlowBadge = () => {
    return (
        <div className="relative inline-block">
            <div className="absolute inset-0 blur-xl bg-gradient-to-r from-purple-600/50 to-blue-600/50 animate-spin-slow rounded-full" />
            <span className="relative inline-block bg-gradient-to-r from-purple-600 to-blue-600 text-white text-sm font-medium rounded-full px-4 py-1.5">
                AI-Powered Video Intelligence Platform
            </span>
        </div>
    );
};

export default GlowBadge;
