import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";

interface TooltipProps {
    content: React.ReactNode;
    children: React.ReactNode;
    placement?: "top" | "bottom" | "left" | "right";
    className?: string;
    offset?: number;
}

const Tooltip: React.FC<TooltipProps> = ({
    content,
    children,
    placement = "top",
    className = "",
    offset = 8,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isPositioned, setIsPositioned] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const triggerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const updatePosition = () => {
        if (!triggerRef.current || !tooltipRef.current) return;

        const triggerRect = triggerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();

        let top = 0;
        let left = 0;

        switch (placement) {
            case "top":
                top = triggerRect.top - tooltipRect.height - offset;
                left = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
                break;
            case "bottom":
                top = triggerRect.bottom + offset;
                left = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
                break;
            case "left":
                top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
                left = triggerRect.left - tooltipRect.width - offset;
                break;
            case "right":
                top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
                left = triggerRect.right + offset;
                break;
        }

        const padding = 8;
        left = Math.max(padding, Math.min(left, window.innerWidth - tooltipRect.width - padding));
        top = Math.max(padding, Math.min(top, window.innerHeight - tooltipRect.height - padding));

        setTooltipPosition({ top, left });
        setIsPositioned(true);
    };

    useEffect(() => {
        if (isVisible) {
            setIsPositioned(false);
            updatePosition();
            window.addEventListener("scroll", updatePosition, true);
            window.addEventListener("resize", updatePosition);

            return () => {
                window.removeEventListener("scroll", updatePosition, true);
                window.removeEventListener("resize", updatePosition);
            };
        }
    }, [isVisible]);

    const tooltipContent = isVisible && (
        <div
            ref={tooltipRef}
            style={{
                position: "fixed",
                top: tooltipPosition.top,
                left: tooltipPosition.left,
                zIndex: 9999,
                pointerEvents: "none",
                opacity: isPositioned ? 1 : 0,
                transition: "opacity 0.1s",
            }}
            className={`
                bg-gray-900 text-white px-2 py-1 rounded text-sm 
                shadow-lg whitespace-nowrap ${className}
            `}
        >
            {content}
            <div
                className={`
                    absolute w-2 h-2 bg-gray-900 rotate-45
                    ${placement === "bottom" ? "-top-1" : ""}
                    ${placement === "top" ? "-bottom-1" : ""}
                    ${placement === "left" ? "-right-1" : ""}
                    ${placement === "right" ? "-left-1" : ""}
                    ${placement === "top" || placement === "bottom" ? "left-1/2 -translate-x-1/2" : ""}
                    ${placement === "left" || placement === "right" ? "top-1/2 -translate-y-1/2" : ""}
                `}
            />
        </div>
    );

    return (
        <>
            <div
                ref={triggerRef}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                className="inline-block"
            >
                {children}
            </div>
            {tooltipContent && createPortal(tooltipContent, document.body)}
        </>
    );
};

export default Tooltip;
