import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { AiFillDelete, AiOutlineQuestionCircle, AiOutlinePlus } from "react-icons/ai";

import IndexTile, { collection } from "../components/IndexTile";
import Dropdown from "../components/Select";

import { parseDuration } from "../components/utils/duration";

import Collections, { collections_intro_text } from "./Collections";

import logEvent from "../amplitude";

const Home: React.FC = () => {
    const token = localStorage.getItem("token");

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState<string>("");

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [totalDuration, setTotalDuration] = useState<number>(0);
    const [totalSize, setTotalSize] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number[]>([]);
    const [error, setError] = useState<string>();

    const [collections, setCollections] = useState<collection[]>([]);
    const [selectedCollection, setSelectedCollection] = useState<collection | null>(null);

    const [usage, setUsage] = useState<number>(0);
    const [allotedMinutes, setAllotedMinutes] = useState<number>(0);

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Home",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    const getUser = async () => {
        const first_name = localStorage.getItem("first_name");

        if (first_name) {
            setFirstName(first_name);
            return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API}/api/get_user_details`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        localStorage.setItem("first_name", response.data.first_name);
    };

    const getUsage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/get_usage`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const { usage, minutes_allotted } = response.data;

            setUsage(usage);
            setAllotedMinutes(minutes_allotted);
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getIndexes = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/indexes`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const sortedCollections = response.data.indexes.sort(
                (a: collection, b: collection) =>
                    new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            setCollections(sortedCollections);

            setSelectedCollection(sortedCollections[0]);
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    useEffect(() => {
        getUser();
        getIndexes();
        getUsage();
    }, [getIndexes, token]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []).filter(file =>
            file.type.includes("video")
        );
        setSelectedFiles(files);

        let duration = 0;
        let size = 0;

        for (const file of files) {
            const fileDuration = await getDuration(file);
            duration += fileDuration;
            size += file.size;
        }

        setTotalDuration(duration);
        setTotalSize(size);
    };

    const removeFile = async (index: number) => {
        const updatedFiles = [...selectedFiles];
        const removedFile = updatedFiles.splice(index, 1)[0];

        setSelectedFiles(updatedFiles);
        const removedFileDuration = await getDuration(removedFile);
        setTotalDuration(prevDuration => prevDuration - removedFileDuration);
        setTotalSize(prevSize => prevSize - removedFile.size);
    };

    const getDuration = (file: File): Promise<number> => {
        return new Promise<number>(resolve => {
            const video = document.createElement("video");
            video.preload = "metadata";
            video.onloadedmetadata = () => {
                resolve(video.duration);
            };

            video.src = URL.createObjectURL(file);
        });
    };

    const uploadVideo = async () => {
        if (!selectedFiles.length) return;
        setUploading(true);
        const uploadProgress: number[] = new Array(selectedFiles.length).fill(0);
        setProgress(uploadProgress);

        try {
            await Promise.all(
                selectedFiles.map(async (file, index) => {
                    const extension = file.name.split(".").pop();

                    // Start upload for each file
                    const response = await axios.post(
                        `${process.env.REACT_APP_API}/api/upload`,
                        {
                            index_id: selectedCollection?.id,
                            file_type: file.type,
                            file_extension: extension,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    const { url, file_id, download_url } = response.data;
                    const formData = new FormData();

                    Object.entries(url.fields).forEach(([key, value]) => {
                        if (typeof value === "string") {
                            formData.append(key, value);
                        }
                    });

                    formData.append("file", file);

                    await axios.post(url.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: progressEvent => {
                            const total = progressEvent.total ? progressEvent.total : 1;
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / total
                            );
                            uploadProgress[index] = percentCompleted;
                            setProgress([...uploadProgress]);
                        },
                    });

                    // After upload, add record for each file
                    await axios.post(
                        `${process.env.REACT_APP_API}/api/add_records`,
                        {
                            index_id: selectedCollection?.id,
                            file_id,
                            file_name: file.name,
                            file_extension: extension,
                            file_size: file.size,
                            file_url: download_url,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                })
            );
        } catch (error) {
            console.error("Upload failed:", error);
            setError("Upload failed");
        } finally {
            setUploading(false);
            setSelectedFiles([]);
            setProgress([]);
            navigate("/collections/" + selectedCollection?.id);
        }
    };

    const handleCollectionSelect = (collectionId: string) => {
        const collection = collections.find(
            // @ts-ignore
            c => c.id === collectionId
        );
        setSelectedCollection(collection || null);
    };

    const handleModalShow = () => {
        // Logic to open modal to create a new collection
    };

    return (
        <div className="App h-screen flex flex-col ">
            <div className="px-24">
                <div className="flex justify-between items-center mb-10">
                    <div className="">
                        <p className="text-4xl font-bold">Hello, {firstName}!</p>
                    </div>
                    <div className="flex flex-col gap-2 justify-center">
                        <p className="text-sm font-bold">
                            Usage: {usage} / {allotedMinutes} minutes
                        </p>
                        <progress
                            className="progress progress-primary w-56"
                            value={usage}
                            max={allotedMinutes}
                        ></progress>
                    </div>
                </div>

                <div className="h-full bg-slate-50 p-10 mb-10">
                    <p className="text-xl font-bold">Upload videos to get started</p>

                    <div className="mt-4 w-60 flex flex-col gap-3">
                        <p>Select a collection</p>
                        <Dropdown
                            items={collections.map(c => ({
                                label: c.name,
                                value: c.id.toString(),
                            }))}
                            onSelect={handleCollectionSelect}
                            selected={selectedCollection?.id.toString()}
                        />
                    </div>
                    <div className="flex flex-col gap-4 mt-6">
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text">Please select video files</span>
                            </div>
                            <input
                                type="file"
                                className="file-input file-input-bordered w-96"
                                onChange={handleFileChange}
                                multiple
                                accept="video/*"
                            />
                        </label>
                        <div className="mt-4">
                            {selectedFiles.map((file, index) => (
                                <div key={index} className="flex items-center mb-2 gap-6">
                                    <span className="text-sm w-[50%] truncate">{file.name}</span>

                                    {!uploading && (
                                        <AiFillDelete
                                            className="text-red-500 cursor-pointer"
                                            onClick={() => removeFile(index)}
                                            size={20}
                                        />
                                    )}

                                    {uploading && (
                                        <div className="flex gap-2 items-center">
                                            <progress
                                                className="progress progress-primary w-56"
                                                value={progress[index]}
                                                max="100"
                                            ></progress>
                                            <span className="text-sm">{progress[index]}%</span>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className="flex justify-end gap-4 mt-4">
                                <button
                                    className="btn btn-primary text-white"
                                    onClick={uploadVideo}
                                    disabled={selectedFiles.length <= 0}
                                >
                                    Upload
                                </button>
                            </div>

                            {selectedFiles.length > 0 && (
                                <div className="mt-4">
                                    <p className="text-sm">
                                        Total Duration: {parseDuration(totalDuration)}{" "}
                                    </p>
                                    {/* <p className="text-sm">
                                    Total Size: {totalSize} bytes
                                </p> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-32">
                <Collections />
            </div>

            {/* <div>
                <div className="flex flex-col mb-10">
                    <h1 className="text-4xl font-bold">Collections</h1>
                    <div className="flex items-center gap-2">
                        <p className="text-lg">Manage and view your collections</p>
                        <div className="tooltip" data-tip={collections_intro_text}>
                            <AiOutlineQuestionCircle className="text-primary" />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 2xl:w-[80%]">
                    {collections.map(collection => (
                        <IndexTile key={collection.id} collection={collection} />
                    ))}
                </div>
            </div> */}
        </div>
    );
};

export default Home;
