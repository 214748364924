import React from "react";

import blank from "../../assets/blank.jpg";

import { Score } from "../../pages/Search";

interface props {
    video: Video;
}

export interface Video {
    score: number;
    start_time: number;
    end_time: number;
    video_id: string;
    video_name?: string;
    url?: string;
    types: ["speech", "visual", "ocr"];
    text?: string;
    thumbnail_url: string;
    stream_url?: string;
    subtitle_url?: string;
    file_name?: string;
}

const confidenceBadge = (confidence: number) => {
    if (confidence < Score.medium) {
        return (
            <div className="badge bg-red-500 border-none text-white font-medium shadow-md">Low</div>
        );
    } else if (confidence <Score.high) {
        return (
            <div className="badge bg-yellow-500 border-none text-white font-medium shadow-md">
                Medium
            </div>
        );
    } else {
        return (
            <div className="badge bg-green-500 border-none text-white font-medium shadow-md">
                High
            </div>
        );
    }
};

const VideoTile: React.FC<props> = ({ video }) => {
    return (
        <div>
            <div className="relative w-56 h-36">
                <img
                    src={video.thumbnail_url ? video.thumbnail_url : blank}
                    className="w-full h-full object-cover rounded-md"
                    alt={video.video_id}
                    loading="lazy"
                    onError={e => {
                        e.currentTarget.src = blank;
                    }}
                />
                <div className="absolute top-2 right-2">{confidenceBadge(video.score)}</div>
                <div className="flex justify-center gap-2 absolute bottom-0 w-full p-2 bg-black bg-opacity-50">
                    {video.types.includes("visual") && (
                        // <img src={visual} className="w-8" alt="visual" />
                        <div className="badge badge-outline text-white">visual</div>
                    )}
                    {video.types.includes("speech") && (
                        // <img src={audio} className="w-8" alt="speech" />
                        <div className="badge badge-outline text-white">conversation</div>
                    )}
                    {video.types.includes("ocr") && (
                        // <img src={text} className="w-8" alt="text_in_video" />
                        <div className="badge badge-outline text-white">text</div>
                    )}
                </div>

                <p className="truncate w-full text-center mt-2">{video.video_name}</p>
            </div>
        </div>
    );
};

export default VideoTile;
