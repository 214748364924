import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import TimestampControl from "./TimestampControl";
import { AiOutlineSend } from "react-icons/ai";
import { debounce } from "lodash";

interface Comment {
    id: string;
    content: string;
    start_time: number;
    end_time: number;
    user_email: string;
    first_name: string;
    last_name: string;
    index_id: string;
    created_at: string;
    updated_at: string;
    is_edited: boolean;
    reply_count: number;
    replies?: Comment[];
    parent_comment?: string | null;
}

interface AddCommentProps {
    videoId: string;
    indexId: string;
    currentTime: number;
    isPlaying: boolean;
    setIsPlaying: (playing: boolean) => void;
    onCommentAdded: () => void;
    parentComment?: Comment | null;
    onCancel?: () => void;
    initialContent?: string;
    commentId?: string;
    isEditing?: boolean;
    shareToken?: string;
    currentUserEmail?: string;
}

export const AddComment: React.FC<AddCommentProps> = ({
    videoId,
    indexId,
    currentTime,
    isPlaying,
    setIsPlaying,
    onCommentAdded,
    parentComment = null,
    onCancel,
    initialContent = "",
    commentId,
    isEditing = false,
    shareToken,
    currentUserEmail,
}) => {
    const [content, setContent] = useState(initialContent);
    const [startTime, setStartTime] = useState<number | null>(null);
    const [endTime, setEndTime] = useState<number | null>(null);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const wasEmptyRef = useRef<boolean>(!initialContent);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const abortControllerRef = useRef<AbortController | null>(null);

    const isReply = Boolean(parentComment);
    const isAnonymousUser = Boolean(shareToken) || !currentUserEmail;

    useEffect(() => {
        if (startTime !== null && content.trim() && currentTime !== startTime && !isReply && !isEditing) {
            setEndTime(currentTime);
        }
    }, [currentTime, startTime, content, isReply, isEditing]);

    useEffect(() => {
        if (isEditing) {
            setStartTime(currentTime);
        }
    }, [isEditing, currentTime]);

    const getHeaders = useCallback(() => {
        if (shareToken) {
            return { "X-Share-Token": shareToken };
        }
        const token = localStorage.getItem("token");
        return { Authorization: `Bearer ${token}` };
    }, [shareToken]);

    const fetchSuggestions = useCallback(
        async (text: string) => {
            if (isAnonymousUser) {
                return;
            }

            if (!text.trim() || text.length < 3) {
                setSuggestions([]);
                setShowSuggestions(false);
                return;
            }

            try {
                if (abortControllerRef.current) {
                    abortControllerRef.current.abort();
                }

                abortControllerRef.current = new AbortController();
                setIsFetching(true);

                const response = await axios.get(
                    `${process.env.REACT_APP_API}/api/quick-review/comment-suggestions/${indexId}`,
                    {
                        params: { text },
                        headers: getHeaders(),
                        signal: abortControllerRef.current.signal,
                    }
                );

                setSuggestions(response.data.suggestions);
                setShowSuggestions(response.data.suggestions.length > 0);
                setSelectedSuggestionIndex(-1);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error("Error fetching suggestions:", error);
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } finally {
                setIsFetching(false);
            }
        },
        [indexId, getHeaders, isAnonymousUser]
    );

    const debouncedFetchSuggestions = useMemo(
        () => debounce(fetchSuggestions, 150),
        [fetchSuggestions]
    );

    useEffect(() => {
        return () => {
            debouncedFetchSuggestions.cancel();
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [debouncedFetchSuggestions]);

    const handleCommentChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newValue = e.target.value;
            const isEmpty = newValue.trim().length === 0;

            if (isEmpty) {
                wasEmptyRef.current = true;
                setSuggestions([]);
                setShowSuggestions(false);
                setEndTime(null);
            } else if (wasEmptyRef.current && !isEditing && !isReply) {
                setIsPlaying(false);
                setStartTime(currentTime === 0 ? 0.2 : currentTime);
                wasEmptyRef.current = false;
            }

            setContent(newValue);
            debouncedFetchSuggestions(newValue);

            if (textareaRef.current) {
                textareaRef.current.style.height = "auto";
                textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 96)}px`;
            }
        },
        [currentTime, debouncedFetchSuggestions, isEditing, isReply, setIsPlaying]
    );

    const handleSubmit = async (): Promise<void> => {
        if (!content.trim()) {
            alert("Please enter a comment");
            return;
        }

        if (!isReply && !isEditing && !startTime) {
            alert("Please ensure you have a timestamp");
            return;
        }

        setIsSubmitting(true);
        try {
            const endpoint = isEditing
                ? `${process.env.REACT_APP_API}/api/quick-review/update-comment/${videoId}/${commentId}`
                : `${process.env.REACT_APP_API}/api/quick-review/create-comment/${videoId}`;

            const method = isEditing ? "PUT" : "POST";

            const timeData =
                isReply && parentComment
                    ? {
                          start_time: parentComment.start_time,
                          end_time: parentComment.end_time,
                      }
                    : {
                          start_time: startTime!,
                          end_time: endTime || startTime!,
                      };

            const body = isEditing
                ? { content }
                : {
                      content,
                      ...timeData,
                      ...(isReply && parentComment && { parent_comment_id: parentComment.id }),
                  };

            await axios({
                method,
                url: endpoint,
                data: body,
                headers: getHeaders(),
            });

            setContent("");
            if (!isEditing && !isReply) {
                setStartTime(null);
                setEndTime(null);
                wasEmptyRef.current = true;
            }
            setSuggestions([]);
            setShowSuggestions(false);
            onCommentAdded();
            onCancel?.();
        } catch (error) {
            console.error("Error posting comment:", error);
            alert("Failed to post comment. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            const isButtonEnabled = (startTime || isEditing || isReply) && content.trim();

            if (e.key === "Tab" && showSuggestions && suggestions.length > 0) {
                e.preventDefault();
                const nextIndex = (selectedSuggestionIndex + 1) % suggestions.length;
                setSelectedSuggestionIndex(nextIndex);
                setContent(suggestions[nextIndex]);
            } else if (e.key === "Enter" && e.shiftKey && isButtonEnabled) {
                e.preventDefault();
                handleSubmit();
            } else if (e.key === "Escape") {
                setShowSuggestions(false);
            }

            if (e.code === "Space" && document.activeElement === e.target) {
                e.stopPropagation();
            }
        },
        [
            showSuggestions,
            suggestions.length,
            selectedSuggestionIndex,
            content,
            startTime,
            isEditing,
            isReply,
        ]
    );

    const isButtonEnabled = (startTime || isEditing || isReply) && content.trim();

    const SuggestionsList = useMemo(() => {
        if (!showSuggestions || suggestions.length === 0 || isAnonymousUser) return null;

        return (
            <div className="absolute bottom-full left-0 right-0 mb-1 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                {suggestions.map((suggestion, index) => (
                    <div
                        key={index}
                        className={`p-2 cursor-pointer hover:bg-gray-100 ${
                            index === selectedSuggestionIndex ? "bg-gray-100" : ""
                        }`}
                        onClick={() => {
                            setContent(suggestion);
                            setShowSuggestions(false);
                        }}
                    >
                        {suggestion}
                    </div>
                ))}
                <div className="p-1 text-xs text-gray-500 border-t">
                    Press Tab to cycle through suggestions
                </div>
            </div>
        );
    }, [showSuggestions, suggestions, selectedSuggestionIndex, isAnonymousUser]);

    const handleTimeUpdate = (newStart: number | null, newEnd: number | null) => {
        setStartTime(newStart);
        setEndTime(newEnd);
        if (newEnd) {
            setIsPlaying(false);
            const videoElement = document.querySelector('video');
            if (videoElement) {
                videoElement.currentTime = newEnd;
            }
        }
    };

    return (
        <div className="bg-gray-100 p-2 rounded-md shadow-sm space-y-2 relative">
            {SuggestionsList}
            <div className="flex flex-col gap-2">
                <div className="relative w-full">
                    <textarea
                        ref={textareaRef}
                        placeholder={
                            isReply ? "Write a reply..." : "Start typing to add a comment..."
                        }
                        value={content}
                        onChange={handleCommentChange}
                        onKeyDown={handleKeyDown}
                        className={`textarea textarea-bordered w-full min-h-[36px] max-h-24 py-2 resize-none overflow-y-auto focus:outline-none ${
                            isFetching ? "bg-gray-50" : ""
                        }`}
                    />
                    {isFetching && !isAnonymousUser && (
                        <div className="absolute right-2 top-2">
                            <div className="w-4 h-4 border-2 border-gray-300 border-t-primary rounded-full animate-spin" />
                        </div>
                    )}
                </div>

                <div className="flex flex-col-reverse gap-2 sm:flex-row sm:items-center sm:justify-between">
                    {!isEditing && !isReply && (
                        <div className="w-full sm:w-auto">
                            <TimestampControl
                                startTime={startTime}
                                endTime={endTime}
                                currentTime={currentTime}
                                onSetTime={() => setStartTime(currentTime)}
                                onClear={() => {
                                    setStartTime(null);
                                    setEndTime(null);
                                    setContent("");
                                    wasEmptyRef.current = true;
                                }}
                                onTimeUpdate={handleTimeUpdate}
                            />
                        </div>
                    )}

                    <div className="flex justify-end gap-2 w-full sm:w-auto">
                        {onCancel && (
                            <button 
                                className="btn btn-ghost btn-sm flex-1 sm:flex-initial" 
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </button>
                        )}
                        <div
                            className="tooltip tooltip-left flex-1 sm:flex-initial"
                            data-tip="Press Shift + Enter to submit"
                        >
                            <button
                                className={`btn btn-sm text-white w-full sm:w-[80px] ${
                                    !isButtonEnabled || isSubmitting ? "btn-disabled" : "btn-primary"
                                }`}
                                onClick={handleSubmit}
                                disabled={!isButtonEnabled || isSubmitting}
                            >
                                {isSubmitting ? (
                                    <div className="w-4 h-4 border-2 border-gray-300 border-t-white rounded-full animate-spin" />
                                ) : (
                                    <AiOutlineSend className="text-white" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddComment;