import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import logEvent from "../amplitude";

import CollectionHeader from "../components/CollectionDetail/CollectionHeader";

import Modal from "../components/Modal";
import VideoTile from "../components/VideoTile/DefaultVideoTile";
import { Video } from "../components/VideoTile/DefaultVideoTile";
import { parseDuration } from "../components/utils/duration";

import {
    AiOutlineDelete as DeleteIcon,
    AiOutlineUpload,
    AiOutlineDownload,
    AiOutlineCloudUpload,
    AiOutlineFile,
    AiOutlineClose,
    AiOutlineWarning,
} from "react-icons/ai";
import ReactPlayer from "react-player";

import EmptyState from "../components/CollectionDetail/EmptyState";
import UploadProgressModal, {
    UploadingFile,
} from "../components/CollectionDetail/UploadProgressModal";
import {
    handleSubtitleDownload,
    handleVideoDownload,
    handleRetry,
    deleteVideo,
} from "../components/CollectionDetail/utils/videoUtils";
import {
    getSortedVideos,
    getFilteredVideos,
} from "../components/CollectionDetail/utils/videoSortingUtils";
import { SORT_OPTIONS } from "../components/CollectionDetail/constants";

const CollectionDetail: React.FC = () => {
    const { id } = useParams();

    const userId = localStorage.getItem("user_id");

    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [name, setName] = useState<string>("");
    const [videos, setVideos] = useState<Video[]>([]);

    const [visual, setVisual] = useState<boolean>(false);
    const [ocr, setOcr] = useState<boolean>(false);
    const [audio, setAudio] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);

    const [showPlayModal, setShowPlayModal] = useState<boolean>(false);
    const [selectedVideo, setSelectedVideo] = useState<Video>();

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [videoToDelete, setVideoToDelete] = useState<Video | null>(null);

    const [sortBy, setSortBy] = useState<string>("newest");

    const [filterQuery, setFilterQuery] = useState<string>("");

    useEffect(() => {
        logEvent("page_view", {
            page: "CollectionDetail",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    const handleFileChange = (event: any) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChooseFiles = () => {
        fileInputRef.current?.click();
    };

    const removeSelectedFile = (index: number) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const fetchDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/indexes/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const hasPending = response.data.videos.some(
                (video: Video) => video.status !== "SUCCESS" && video.status !== "ERROR"
            );

            setName(response.data.collection);
            setVideos(response.data.videos);
            setVisual(response.data.visual);
            setOcr(response.data.ocr);
            setAudio(response.data.audio);

            if (hasPending) {
                setTimeout(fetchDetails, 5000);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, [id, token, userId]);

    const isCollectionOwner = () => {
        if (!userId) {
            return false;
        }

        if (videos.length === 0) {
            return true;
        }

        if (userId === videos[0].user_id.toString()) {
            return true;
        }

        return false;
    };

    const uploadVideo = async () => {
        if (!selectedFiles.length) return;

        const filesToUpload = [...selectedFiles];

        setShowModal(false);
        setSelectedFiles([]);

        const newUploadingFiles: UploadingFile[] = filesToUpload.map(file => ({
            file,
            progress: 0,
            speed: 0,
            remainingTime: 0,
            status: "uploading",
            cancelToken: axios.CancelToken.source(),
            startTime: Date.now(),
        }));

        setUploadingFiles(prev => [...prev, ...newUploadingFiles]);

        try {
            await Promise.all(
                newUploadingFiles.map(async uploadingFile => {
                    const file = uploadingFile.file;
                    const extension = file.name.split(".").pop();

                    try {
                        const response = await axios.post(
                            `${process.env.REACT_APP_API}/api/upload`,
                            {
                                index_id: id,
                                file_type: file.type,
                                file_extension: extension,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                                cancelToken: uploadingFile.cancelToken.token,
                            }
                        );

                        const { url, file_id, download_url } = response.data;
                        const formData = new FormData();

                        Object.entries(url.fields).forEach(([key, value]) => {
                            if (typeof value === "string") {
                                formData.append(key, value);
                            }
                        });

                        formData.append("file", file);

                        await axios.post(url.url, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            cancelToken: uploadingFile.cancelToken.token,
                            onUploadProgress: progressEvent => {
                                const total = progressEvent.total ? progressEvent.total : 1;
                                const percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) / total
                                );
                                updateUploadProgress(
                                    file,
                                    percentCompleted,
                                    uploadingFile.startTime
                                );
                            },
                        });

                        await axios.post(
                            `${process.env.REACT_APP_API}/api/add_records`,
                            {
                                index_id: id,
                                file_id,
                                file_name: file.name,
                                file_extension: extension,
                                file_size: file.size,
                                file_url: download_url,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                    } catch (error) {
                        if (!axios.isCancel(error)) {
                            setUploadingFiles(prev => {
                                const fileIndex = prev.findIndex(f => f.file === file);
                                if (fileIndex === -1) return prev;
                                const newFiles = [...prev];
                                newFiles[fileIndex] = {
                                    ...newFiles[fileIndex],
                                    status: "error",
                                };
                                return newFiles;
                            });
                        }
                        throw error;
                    }
                })
            );

            fetchDetails();
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error("Upload failed:", error);
            }
        } finally {
            setTimeout(() => {
                setUploadingFiles(prev => prev.filter(f => f.status === "uploading"));
            }, 3000);
        }
    };

    const updateUploadProgress = (file: File, progress: number, startTime: number) => {
        setUploadingFiles(prev => {
            const fileIndex = prev.findIndex(f => f.file === file);
            if (fileIndex === -1) return prev;

            const elapsedTime = (Date.now() - startTime) / 1000;
            const uploadedBytes = (file.size * progress) / 100;
            const speed = uploadedBytes / elapsedTime;
            const remainingBytes = file.size - uploadedBytes;
            const remainingTime = remainingBytes / speed;

            const newFiles = [...prev];
            newFiles[fileIndex] = {
                ...newFiles[fileIndex],
                progress,
                speed,
                remainingTime,
                status: progress === 100 ? "completed" : "uploading",
            };
            return newFiles;
        });
    };

    const cancelUpload = (file: File) => {
        const uploadingFile = uploadingFiles.find(f => f.file === file);
        if (uploadingFile) {
            uploadingFile.cancelToken.cancel("Upload cancelled by user");
            setUploadingFiles(prev => prev.filter(f => f.file !== file));
        }
    };

    const handleDeleteClick = (video: Video) => {
        setVideoToDelete(video);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (videoToDelete) {
            setVideos(prevVideos =>
                prevVideos.map(video =>
                    video.id === videoToDelete.id ? { ...video, status: "DELETING" } : video
                )
            );

            setShowDeleteModal(false);
            setVideoToDelete(null);
            setShowPlayModal(false);

            deleteVideo(videoToDelete.id, videoToDelete.file_url, id!, token!, fetchDetails);
        }
    };

    const handleVideoSelect = (video: Video) => {
        setSelectedVideo(video);
        setShowPlayModal(!showPlayModal);
    };

    const handleQuickReview = (video: Video) => {
        navigate(`/quick-review/${video.user_id}/${video.id}`);
    };

    const totalDuration = () => {
        let total = 0;
        videos.forEach(video => {
            total += video.video_duration;
        });
        return parseDuration(total);
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center mt-[100px]">
                <span className="loading loading-spinner loading-lg text-primary"></span>
            </div>
        );
    }

    if (!id || !name) {
        return (
            <div className="h-screen px-24 flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-2xl font-semibold text-gray-700">Collection Not Found</h2>
                    <p className="text-gray-500 mt-2">
                        The collection you're looking for doesn't exist or you don't have access.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="h-screen px-24">
            <CollectionHeader
                name={name}
                id={id}
                isCollectionOwner={isCollectionOwner()}
                onUpload={handleModal}
                visual={visual}
                audio={audio}
                ocr={ocr}
                videosCount={videos.length}
                totalDuration={totalDuration()}
                filterQuery={filterQuery}
                onFilter={setFilterQuery}
                sortOptions={SORT_OPTIONS}
                selectedSort={sortBy}
                onSortChange={setSortBy}
            />

            <br />

            <div>
                {videos.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                        {getSortedVideos(getFilteredVideos(videos, filterQuery), sortBy).map(
                            video => (
                                <VideoTile
                                    key={video.id}
                                    video={video}
                                    handleVideoSelect={handleVideoSelect}
                                    handleQuickReview={handleQuickReview}
                                    handleSubtitleDownload={() =>
                                        handleSubtitleDownload(video.subtitle_url)
                                    }
                                    handleVideoDownload={() =>
                                        handleVideoDownload(video.id, video.file_name, token!)
                                    }
                                    handleVideoDelete={handleDeleteClick}
                                    handleRetry={video => handleRetry(video, token!, fetchDetails)}
                                />
                            )
                        )}
                    </div>
                ) : (
                    <EmptyState isCollectionOwner onUpload={handleModal} />
                )}
            </div>

            <Modal isOpen={showModal} onClose={handleModal} title="Upload Files">
                <div className="flex flex-col items-center gap-6 p-6">
                    <div className="text-center">
                        <AiOutlineCloudUpload className="text-6xl text-primary mx-auto mb-4" />
                        <h2 className="text-2xl font-semibold mb-2">Upload Your Videos</h2>
                        <p className="text-gray-600">
                            Choose video files to add to your collection
                        </p>
                    </div>

                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        multiple
                        className="hidden"
                        accept="video/*"
                    />

                    <button
                        className="btn btn-primary text-white px-8 text-lg"
                        onClick={handleChooseFiles}
                    >
                        <AiOutlineFile className="mr-2" />
                        Choose Files
                    </button>

                    {selectedFiles.length > 0 && (
                        <div className="w-full max-h-[40vh] overflow-y-auto">
                            <h3 className="font-semibold mb-2 sticky top-0 bg-white py-2">
                                Selected Files:
                            </h3>
                            <div className="space-y-2">
                                {selectedFiles.map((file, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-between items-center bg-gray-100 p-2 rounded-lg"
                                    >
                                        <span className="text-sm truncate max-w-[300px] ml-2">
                                            {file.name}
                                        </span>
                                        <button
                                            className="btn btn-ghost rounded-full"
                                            onClick={() => removeSelectedFile(index)}
                                        >
                                            <AiOutlineClose className="text-black-500" size={16} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="flex justify-end gap-4 w-full mt-auto">
                        <button className="btn btn-ghost" onClick={handleModal}>
                            Cancel
                        </button>
                        {selectedFiles.length > 0 && (
                            <button className="btn btn-primary text-white" onClick={uploadVideo}>
                                <AiOutlineUpload className="mr-2" />
                                Upload
                            </button>
                        )}
                    </div>
                </div>
            </Modal>

            <UploadProgressModal uploadingFiles={uploadingFiles} onCancel={cancelUpload} />

            <Modal
                isOpen={showPlayModal}
                onClose={() => setShowPlayModal(false)}
                title={selectedVideo?.file_name}
            >
                <div className="flex flex-col gap-4">
                    {selectedVideo && selectedVideo.stream_url && (
                        <ReactPlayer
                            url={selectedVideo.stream_url}
                            controls
                            width="100%"
                            height="100%"
                            playing
                            config={{
                                file: {
                                    attributes: {
                                        crossOrigin: "anonymous",
                                    },
                                    tracks: selectedVideo.subtitle_url
                                        ? [
                                              {
                                                  kind: "subtitles",
                                                  src: selectedVideo.subtitle_url,
                                                  srcLang: "en",
                                                  label: "English",
                                                  default: true,
                                              },
                                          ]
                                        : [],
                                },
                            }}
                        />
                    )}
                </div>

                <div className="flex justify-between w-full mt-4">
                    <DeleteIcon
                        className="text-3xl cursor-pointer hover:bg-slate-100 rounded-full mt-2 justify-center"
                        onClick={() => handleDeleteClick(selectedVideo!)}
                    />

                    <button
                        className="btn btn-ghost "
                        onClick={() => {
                            handleVideoDownload(selectedVideo!.id, selectedVideo!.file_url, token!);
                        }}
                    >
                        <AiOutlineDownload size={20} />
                        Download
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title="Confirm Delete"
            >
                <div className="flex flex-col items-center gap-4 p-6">
                    <AiOutlineWarning className="text-6xl text-yellow-500" />
                    <h2 className="text-xl font-semibold text-center">
                        Are you sure you want to delete this video?
                    </h2>
                    <p className="text-gray-600 text-center">
                        Deleting this video will not reset your minutes used. This action cannot be
                        undone.
                    </p>
                    <div className="flex justify-center gap-4 mt-4">
                        <button className="btn btn-ghost" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </button>
                        <button className="btn btn-error text-white" onClick={confirmDelete}>
                            Confirm Delete
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CollectionDetail;
