import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
    useEffect,
    useCallback,
  } from "react";
  import ReactPlayer, { ReactPlayerProps } from "react-player";
  import Tooltip from "../Tooltip";
  import {
    MdPlayArrow,
    MdPause,
    MdVolumeUp,
    MdVolumeOff,
    MdVolumeDown,
    MdFullscreen,
    MdFullscreenExit,
    MdSettings,
    MdReplay10,
    MdForward10,
  } from "react-icons/md";
  import { Comment } from "../QuickReview/types";
  
  export interface VideoPlayerHandle {
    getCurrentTime: () => number | undefined;
    seekTo: (amount: number, type?: "seconds" | "fraction") => void;
    togglePlay: () => void;
    toggleMute: () => void;
    setVolume: (volume: number) => void;
  }
  
  interface VideoPlayerProps extends ReactPlayerProps {
    comments?: Comment[];
    onPlayPause?: (playing: boolean) => void;
    onTimeUpdate?: (time: number) => void;
    onCommentClick?: (comment: Comment) => void;
    className?: string;
  }
  
  const formatTime = (seconds: number): string => {
    const pad = (num: number): string => num.toString().padStart(2, "0");
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return hours > 0 ? `${hours}:${pad(minutes)}:${pad(secs)}` : `${minutes}:${pad(secs)}`;
  };
  
  const VideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
    (
      {
        comments = [],
        playing: propPlaying = false,
        onPlayPause,
        onTimeUpdate,
        onCommentClick,
        className = "",
        ...props
      },
      ref
    ) => {
      const playerRef = useRef<ReactPlayer>(null);
      const containerRef = useRef<HTMLDivElement>(null);
      const timelineRef = useRef<HTMLDivElement>(null);
      const controlsTimeoutRef = useRef<NodeJS.Timeout>();
  
      const [playing, setPlaying] = useState<boolean>(propPlaying);
      const [muted, setMuted] = useState<boolean>(false);
      const [volume, setVolume] = useState<number>(1);
      const [duration, setDuration] = useState<number>(0);
      const [played, setPlayed] = useState<number>(0);
      const [loaded, setLoaded] = useState<number>(0);
      const [buffering, setBuffering] = useState<boolean>(false);
      const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
      const [showControls, setShowControls] = useState<boolean>(true);
      const [showSettings, setShowSettings] = useState<boolean>(false);
      const [hoverTime, setHoverTime] = useState<number | null>(null);
      const [playbackRate, setPlaybackRate] = useState<number>(1);
      const [showComments, setShowComments] = useState<boolean>(true);
      const [isDragging, setIsDragging] = useState<boolean>(false);
      const [seekingTime, setSeekingTime] = useState<number | null>(null);
  
      useImperativeHandle(ref, () => ({
        getCurrentTime: () => playerRef.current?.getCurrentTime(),
        seekTo: (amount, type) => playerRef.current?.seekTo(amount, type),
        togglePlay: () => handleTogglePlay(),
        toggleMute: () => handleToggleMute(),
        setVolume: (v) => handleVolumeChange(v),
      }));
  
      useEffect(() => {
        setPlaying(propPlaying);
      }, [propPlaying]);
  
      useEffect(() => {
        const handleFullscreenChange = () => {
          setIsFullscreen(Boolean(document.fullscreenElement));
        };
  
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        return () => document.removeEventListener("fullscreenchange", handleFullscreenChange);
      }, []);
  
      useEffect(() => {
        if (!isDragging) return;
  
        const handleMouseMove = (e: MouseEvent) => {
          if (!timelineRef.current) return;
          const rect = timelineRef.current.getBoundingClientRect();
          const position = Math.min(Math.max((e.clientX - rect.left) / rect.width, 0), 1);
          const time = position * duration;
          setSeekingTime(time);
          setHoverTime(time);
        };
  
        const handleMouseUp = () => {
          if (seekingTime !== null) {
            playerRef.current?.seekTo(seekingTime, "seconds");
          }
          setIsDragging(false);
          setSeekingTime(null);
        };
  
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
  
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
          document.removeEventListener("mouseup", handleMouseUp);
        };
      }, [isDragging, seekingTime, duration]);
  
      const handleMouseMove = useCallback(() => {
        setShowControls(true);
        if (controlsTimeoutRef.current) {
          clearTimeout(controlsTimeoutRef.current);
        }
        if (playing) {
          controlsTimeoutRef.current = setTimeout(() => {
            setShowControls(false);
            setShowSettings(false);
          }, 3000);
        }
      }, [playing]);
  
      const handleTogglePlay = useCallback(() => {
        setPlaying((prev) => !prev);
        onPlayPause?.(!playing);
      }, [playing, onPlayPause]);
  
      const handleToggleMute = useCallback(() => {
        setMuted((prev) => !prev);
      }, []);
  
      const handleVolumeChange = useCallback((newVolume: number) => {
        const volume = Math.min(Math.max(newVolume, 0), 1);
        setVolume(volume);
        setMuted(volume === 0);
      }, []);
  
      const handleTimelineMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!timelineRef.current) return;
        setIsDragging(true);
        const rect = timelineRef.current.getBoundingClientRect();
        const position = Math.min(Math.max((e.clientX - rect.left) / rect.width, 0), 1);
        const time = position * duration;
        setSeekingTime(time);
        setHoverTime(time);
      };
  
      const handleTimelineClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!timelineRef.current) return;
        const rect = timelineRef.current.getBoundingClientRect();
        const position = Math.min(Math.max((e.clientX - rect.left) / rect.width, 0), 1);
        const time = position * duration;
        playerRef.current?.seekTo(time, "seconds");
        setHoverTime(time);
      };
  
      const seekRelative = useCallback(
        (seconds: number) => {
          const currentTime = playerRef.current?.getCurrentTime() || 0;
          playerRef.current?.seekTo(Math.min(Math.max(currentTime + seconds, 0), duration), "seconds");
        },
        [duration]
      );
  
      const toggleFullscreen = useCallback(() => {
        if (containerRef.current) {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else {
            containerRef.current.requestFullscreen();
          }
        }
      }, []);
  
      const getVolumeIcon = () => {
        if (muted || volume === 0) return <MdVolumeOff />;
        if (volume < 0.5) return <MdVolumeDown />;
        return <MdVolumeUp />;
      };
  
      return (
        <div
          ref={containerRef}
          className={`relative w-full h-full bg-black group ${className}`}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => {
            setShowControls(false);
            setShowSettings(false);
            setHoverTime(null);
          }}
        >
          <ReactPlayer
            ref={playerRef}
            {...props}
            playing={playing}
            volume={volume}
            muted={muted}
            playbackRate={playbackRate}
            onProgress={({ played, playedSeconds, loaded }) => {
              if (!isDragging) setPlayed(played);
              setLoaded(loaded);
              onTimeUpdate?.(playedSeconds);
            }}
            onDuration={setDuration}
            onBuffer={() => setBuffering(true)}
            onBufferEnd={() => setBuffering(false)}
            width="100%"
            height="100%"
          />
  
          {buffering && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/40">
              <div className="loading loading-spinner loading-lg text-white"></div>
            </div>
          )}
  
          {/* Clickable area to toggle play/pause */}
          <div
            className="absolute top-0 left-0 right-0"
            style={{ height: "80%", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              handleTogglePlay();
            }}
          />
  
          {/* Controls in bottom 20% */}
          {showControls && (
            <div
              className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/90 via-black/25 to-transparent"
              style={{ height: "20%" }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Controls Container */}
              <div className="flex flex-col justify-end h-full">
                {/* Comments */}
                {showComments && (
                  <div className="px-4 mb-2">
                    <div className="relative h-8">
                      <div className="absolute inset-0 bg-white/5 rounded">
                        {comments.map((comment: Comment) => {
                          const startPercent = (comment.start_time / duration) * 100;
                          return (
                            <div
                              key={comment.id}
                              className="absolute top-1/2 -translate-y-1/2 group"
                              style={{ left: `${startPercent}%` }}
                            >
                              <div
                                className="cursor-pointer relative"
                                onClick={() => onCommentClick?.(comment)}
                              >
                                <Tooltip
                                  content={`${comment.first_name} ${comment.last_name}: ${comment.content}`}
                                  placement="top"
                                >
                                  <div className="bg-purple-500 text-white rounded-full w-6 h-6 hover:scale-125 transition-transform flex items-center justify-center">
                                    <span className="text-sm font-bold leading-none">
                                      {comment.first_name.charAt(0)}
                                    </span>
                                  </div>
                                </Tooltip>
                                <div
                                  className="absolute top-full mt-1 h-1 bg-purple-500 opacity-50"
                                  style={{
                                    left: 0,
                                    width: `${
                                      ((comment.end_time - comment.start_time) / duration) * 100
                                    }%`,
                                    minWidth: "4px",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
  
                {/* Timeline */}
                <div className="px-4">
                  <div
                    ref={timelineRef}
                    className="relative h-1 bg-white/30 rounded-full cursor-pointer group-hover:h-2 transition-all shadow-lg"
                    onMouseDown={handleTimelineMouseDown}
                    onClick={handleTimelineClick}
                    onMouseMove={(e) => {
                      e.stopPropagation();
                      if (!timelineRef.current) return;
                      const rect = timelineRef.current.getBoundingClientRect();
                      const position = Math.min(Math.max((e.clientX - rect.left) / rect.width, 0), 1);
                      const time = position * duration;
                      if (isDragging) {
                        setSeekingTime(time);
                      }
                      setHoverTime(time);
                    }}
                    onMouseLeave={() => {
                      if (!isDragging) setHoverTime(null);
                    }}
                  >
                    <div
                      className="absolute h-full bg-white/50 rounded-full"
                      style={{ width: `${loaded * 100}%` }}
                    />
  
                    <div
                      className="absolute h-full bg-white rounded-full shadow-glow"
                      style={{
                        width: `${
                          (isDragging && seekingTime !== null ? seekingTime / duration : played) * 100
                        }%`,
                      }}
                    />
  
                    {(hoverTime !== null || (isDragging && seekingTime !== null)) && (
                      <div
                        className="absolute -top-8 transform -translate-x-1/2 bg-black/80 px-2 py-1 rounded text-xs text-white shadow-lg"
                        style={{
                          left: `${
                            (isDragging && seekingTime !== null
                              ? seekingTime / duration
                              : hoverTime! / duration) * 100
                          }%`,
                        }}
                      >
                        {formatTime(
                          isDragging && seekingTime !== null ? seekingTime : hoverTime!
                        )}
                      </div>
                    )}
                  </div>
                </div>
  
                {/* Control Buttons */}
                <div className="flex items-center gap-2 text-white px-4 py-2">
                  <button
                    className="btn btn-ghost btn-sm btn-circle text-white hover:bg-white/20"
                    onClick={handleTogglePlay}
                  >
                    {playing ? <MdPause size={24} /> : <MdPlayArrow size={24} />}
                  </button>
  
                  <button
                    className="btn btn-ghost btn-sm btn-circle text-white hover:bg-white/20"
                    onClick={() => seekRelative(-10)}
                  >
                    <Tooltip content="Rewind 10 seconds" placement="top">
                      <MdReplay10 size={20} />
                    </Tooltip>
                  </button>
                  <button
                    className="btn btn-ghost btn-sm btn-circle text-white hover:bg-white/20"
                    onClick={() => seekRelative(10)}
                  >
                    <Tooltip content="Forward 10 seconds" placement="top">
                      <MdForward10 size={20} />
                    </Tooltip>
                  </button>
  
                  <div className="flex items-center gap-2">
                    <button
                      className="btn btn-ghost btn-sm btn-circle text-white hover:bg-white/20"
                      onClick={handleToggleMute}
                    >
                      {getVolumeIcon()}
                    </button>
                    <input
                      type="range"
                      min={0}
                      max={1}
                      step={0.01}
                      value={muted ? 0 : volume}
                      onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                      className="range range-xs range-white w-24"
                    />
                  </div>
  
                  <div className="text-sm">
                    <span className="text-white">
                      {formatTime(
                        isDragging && seekingTime !== null ? seekingTime : duration * played
                      )}
                    </span>
                    <span className="mx-1 text-white/70">/</span>
                    <span className="text-white/70">{formatTime(duration)}</span>
                  </div>
  
                  <div className="flex-grow" />
  
                  {/* Settings */}
                  <div className="relative">
                    <button
                      className="btn btn-ghost btn-sm btn-circle text-white hover:bg-white/20"
                      onClick={() => setShowSettings(!showSettings)}
                    >
                      <MdSettings size={20} />
                    </button>
                    {showSettings && (
                      <div
                        className="absolute bottom-full right-0 mb-2 w-48 bg-black/90 rounded-box shadow-lg overflow-hidden border border-white/10 z-50"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="p-2">
                          {/* Playback Speed Section */}
                          <div className="mb-4">
                            <div className="text-sm font-medium mb-2 text-white">Playback Speed</div>
                            <div className="grid grid-cols-3 gap-1">
                              {[0.5, 0.75, 1, 1.25, 1.5, 2].map((speed) => (
                                <button
                                  key={speed}
                                  className={`btn btn-sm ${
                                    playbackRate === speed
                                      ? "bg-white text-black hover:bg-white/90"
                                      : "btn-ghost text-white hover:bg-white/20"
                                  }`}
                                  onClick={() => setPlaybackRate(speed)}
                                >
                                  {speed}x
                                </button>
                              ))}
                            </div>
                          </div>
  
                          {/* Comments Toggle Section */}
                          <div className="border-t border-white/10 pt-2">
                            <div className="text-sm font-medium mb-2 text-white">Timeline Comments</div>
                            <div className="flex gap-2">
                              <button
                                className={`btn btn-sm flex-1 ${
                                  showComments
                                    ? "bg-white text-black hover:bg-white/90"
                                    : "btn-ghost text-white hover:bg-white/20"
                                }`}
                                onClick={() => setShowComments(true)}
                              >
                                Show
                              </button>
                              <button
                                className={`btn btn-sm flex-1 ${
                                  !showComments
                                    ? "bg-white text-black hover:bg-white/90"
                                    : "btn-ghost text-white hover:bg-white/20"
                                }`}
                                onClick={() => setShowComments(false)}
                              >
                                Hide
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
  
                  {/* Fullscreen */}
                  <button
                    className="btn btn-ghost btn-sm btn-circle text-white hover:bg-white/20"
                    onClick={toggleFullscreen}
                  >
                    {isFullscreen ? <MdFullscreenExit size={20} /> : <MdFullscreen size={20} />}
                  </button>
                </div>
              </div>
            </div>
          )}
  
          {/* Mobile Touch Controls */}
          <div
            className="absolute inset-0 touch-manipulation md:hidden"
            onTouchStart={(e) => {
              e.stopPropagation();
              const touch = e.touches[0];
              const x = touch.clientX;
              const width = window.innerWidth;
  
              if (x < width / 3) {
                seekRelative(-10);
              } else if (x > (width * 2) / 3) {
                seekRelative(10);
              } else {
                handleTogglePlay();
              }
            }}
          />
        </div>
      );
    }
  );
  
  export default VideoPlayer;
  