import React from "react";
import { CancelTokenSource } from "axios";
import {
    AiOutlineClose,
    AiOutlineFile,
    AiOutlineUpload,
    AiOutlineWarning,
    AiOutlineCheckCircle,
} from "react-icons/ai";

export interface UploadingFile {
    file: File;
    progress: number;
    speed: number;
    remainingTime: number;
    status: "uploading" | "completed" | "error";
    cancelToken: CancelTokenSource;
    startTime: number;
}

const UploadProgressModal: React.FC<{
    uploadingFiles: UploadingFile[];
    onCancel: (file: File) => void;
}> = ({ uploadingFiles, onCancel }) => {
    if (uploadingFiles.length === 0) return null;

    const formatSpeed = (speed: number) => {
        if (speed > 1024 * 1024) return `${(speed / (1024 * 1024)).toFixed(2)} MB/s`;
        if (speed > 1024) return `${(speed / 1024).toFixed(2)} KB/s`;
        return `${speed.toFixed(2)} B/s`;
    };

    const formatTime = (seconds: number) => {
        if (seconds < 60) return `${Math.ceil(seconds)}s`;
        if (seconds < 3600) return `${Math.ceil(seconds / 60)}m ${Math.ceil(seconds % 60)}s`;
        return `${Math.floor(seconds / 3600)}h ${Math.ceil((seconds % 3600) / 60)}m`;
    };

    return (
        <div className="fixed bottom-4 right-4 w-96 bg-white rounded-lg shadow-xl border border-gray-200 z-50">
            <div className="p-4 border-b border-gray-200">
                <h3 className="text-lg font-semibold flex items-center">
                    <AiOutlineUpload className="mr-2" />
                    Uploading Files
                </h3>
            </div>
            <div className="max-h-96 overflow-y-auto">
                {uploadingFiles.map((uploadingFile, index) => (
                    <div
                        key={`${uploadingFile.file.name}-${index}`}
                        className="p-4 border-b border-gray-100 hover:bg-gray-50"
                    >
                        <div className="flex justify-between items-start mb-2">
                            <div className="flex items-center space-x-2 flex-1 min-w-0">
                                {uploadingFile.status === "completed" ? (
                                    <AiOutlineCheckCircle className="text-green-500 flex-shrink-0" />
                                ) : (
                                    <AiOutlineFile className="text-gray-400 flex-shrink-0" />
                                )}
                                <span className="truncate text-sm">{uploadingFile.file.name}</span>
                            </div>
                            {uploadingFile.status === "uploading" && (
                                <button
                                    onClick={() => onCancel(uploadingFile.file)}
                                    className="text-gray-400 hover:text-gray-600"
                                >
                                    <AiOutlineClose />
                                </button>
                            )}
                        </div>
                        <div className="space-y-2">
                            <div className="w-full bg-gray-200 rounded-full h-2">
                                <div
                                    className={`h-2 rounded-full ${
                                        uploadingFile.status === "error"
                                            ? "bg-red-500"
                                            : uploadingFile.status === "completed"
                                              ? "bg-green-500"
                                              : "bg-primary"
                                    }`}
                                    style={{ width: `${uploadingFile.progress}%` }}
                                />
                            </div>
                            {uploadingFile.status === "uploading" && (
                                <div className="flex justify-between text-xs text-gray-500">
                                    <span>{uploadingFile.progress}%</span>
                                    <span>{formatSpeed(uploadingFile.speed)}</span>
                                    <span>{formatTime(uploadingFile.remainingTime)} remaining</span>
                                </div>
                            )}
                            {uploadingFile.status === "completed" && (
                                <div className="text-xs text-green-500 flex items-center justify-center">
                                    Upload complete
                                </div>
                            )}
                        </div>
                        {uploadingFile.status === "error" && (
                            <div className="mt-2 text-xs text-red-500 flex items-center">
                                <AiOutlineWarning className="mr-1" />
                                Upload failed
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UploadProgressModal;
