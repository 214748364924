import React, { useState, useRef, useEffect } from "react";
import { AiOutlineSearch, AiOutlineFilter } from "react-icons/ai";
import Select from "../Select";

interface FilterMenuProps {
    onFilter: (query: string) => void;
    sortOptions: { label: string; value: string }[];
    selectedSort: string;
    onSortChange: (value: string) => void;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
    onFilter,
    sortOptions,
    selectedSort,
    onSortChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={menuRef}>
            <button
                className="btn btn-md bg-gray-100 hover:bg-gray-200 gap-2"
                onClick={() => setIsOpen(!isOpen)}
            >
                <AiOutlineFilter size={18} />
                Filter & Sort
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                    <div className="p-4 space-y-4">
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Filter by filename
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Filter files..."
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        onFilter(e.target.value)
                                    }
                                    className="input input-bordered h-[38px] focus-within:outline-none w-full pl-10"
                                />
                                <AiOutlineSearch
                                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                                    size={18}
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Sort by
                            </label>
                            <Select
                                items={sortOptions}
                                selected={selectedSort}
                                onSelect={onSortChange}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterMenu;
