import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logEvent from "../../amplitude";
import {
    HiOutlineCheck,
    HiOutlineAnnotation,
    HiOutlineShare,
    HiOutlineCloudUpload,
    HiOutlineArrowRight,
    HiOutlinePlay,
} from "react-icons/hi";
import { HiOutlineLanguage } from "react-icons/hi2";

import GridBackground from "../../components/Welcome/GridBackground";
import Footer from "../../components/Footer";

const DEMO_VIDEOS = {
    mainDemo: "https://dsebqouejkpw6.cloudfront.net/Demo/INTRO_REVIEW.mp4",
    transcription: "https://dsebqouejkpw6.cloudfront.net/Demo/QuickReview/TRANSCRIPTION.mp4",
    commenting: "https://dsebqouejkpw6.cloudfront.net/Demo/QuickReview/COMMENTS.mp4",
    sharing: "https://dsebqouejkpw6.cloudfront.net/Demo/QuickReview/SHARING.mp4",
    fileManagement: "https://dsebqouejkpw6.cloudfront.net/Demo/REVIEW/FILE_MANAGEMENT.mp4"
};

const KEY_FEATURES = [
    {
        title: "AI-Powered Transcription",
        description: "Word-level accurate transcription with automatic highlighting as the video plays",
        details: [
            "Real-time word highlighting during playback",
            "Search through transcripts instantly",
            "Navigate video using transcript text",
            "Export transcripts for other uses"
        ],
        video: DEMO_VIDEOS.transcription,
        icon: HiOutlineLanguage
    },
    {
        title: "Precise Video Comments",
        description: "Add frame-perfect comments and select time ranges for detailed feedback",
        details: [
            "Frame-accurate timestamps",
            "Select time ranges for context",
            "Draw directly on frames",
            "Thread discussions"
        ],
        video: DEMO_VIDEOS.commenting,
        icon: HiOutlineAnnotation
    },
    {
        title: "Flexible Sharing & Collaboration",
        description: "Share securely with customizable access levels and anonymous commenting",
        details: [
            "Viewer or collaborator access",
            "Anonymous feedback option",
            "Password protection",
            "Team management"
        ],
        video: DEMO_VIDEOS.sharing,
        icon: HiOutlineShare
    },
    // {
    //     title: "Professional File Management",
    //     description: "Upload and download files in original quality with no compression",
    //     details: [
    //         "Maintain source quality",
    //         "Bulk upload/download",
    //         "Version control",
    //         "Folder organization"
    //     ],
    //     video: DEMO_VIDEOS.fileManagement,
    //     icon: HiOutlineCloudUpload
    // }
];

const QuickReview = () => {
    const navigate = useNavigate();
    const [activeFeature, setActiveFeature] = useState(0);

    useEffect(() => {
        logEvent("page_view", {
            page_title: "QuickReview",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <div className="relative overflow-hidden bg-gradient-to-b from-white to-gray-50 -mt-9">
                <GridBackground gridOpacity={0.5} gridSize={40} gridColor="#CBD5E1" />
                <div className="container mx-auto px-6 pt-32 pb-24 relative">
                    <div className="max-w-4xl mx-auto text-center">
                        <h1 className="text-5xl md:text-7xl font-bold leading-tight mb-6">
                            Supercharge Your{" "}
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                                Video Reviews
                            </span>
                        </h1>

                        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
                            Get precise feedback, instant transcriptions, and seamless approvals in one powerful platform. 
                            Turn complex video reviews into smooth, efficient collaborations.
                        </p>

                        <div className="flex flex-wrap justify-center gap-4 mb-12">
                            <div className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-full hover:bg-blue-100 transition-colors">
                                <HiOutlineLanguage className="w-5 h-5" />
                                <span className="text-sm font-medium">Word-Level AI Transcription</span>
                            </div>
                            <div className="flex items-center gap-2 px-4 py-2 bg-purple-50 text-purple-700 rounded-full hover:bg-purple-100 transition-colors">
                                <HiOutlineAnnotation className="w-5 h-5" />
                                <span className="text-sm font-medium">Frame-Perfect Feedback</span>
                            </div>
                            <div className="flex items-center gap-2 px-4 py-2 bg-green-50 text-green-700 rounded-full hover:bg-green-100 transition-colors">
                                <HiOutlineCloudUpload className="w-5 h-5" />
                                <span className="text-sm font-medium">Studio-Quality Files</span>
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row gap-4 justify-center mb-16">
                            <button
                                className="group px-8 py-4 bg-primary text-white rounded-xl font-medium hover:opacity-90 transition-all duration-200 shadow-lg shadow-blue-500/20 hover:shadow-blue-500/30 hover:-translate-y-0.5"
                                onClick={() => navigate("/signup")}
                            >
                                <span className="flex items-center gap-2">
                                    Start Creating <HiOutlineArrowRight className="w-5 h-5 group-hover:translate-x-0.5 transition-transform" />
                                </span>
                            </button>
                            <button 
                                className="px-8 py-4 bg-white rounded-xl font-medium text-gray-700 hover:bg-gray-50 transition-all duration-200 border border-gray-200 shadow-sm hover:border-gray-300 hover:-translate-y-0.5"
                            >
                                <span className="flex items-center gap-2">
                                    <HiOutlinePlay className="w-5 h-5" /> Watch Demo
                                </span>
                            </button>
                        </div>

                        <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-blue-100 transition-transform hover:scale-[1.01] duration-300">
                            <div className="relative">
                                <video
                                    src={DEMO_VIDEOS.mainDemo}
                                    className="w-full aspect-video object-cover"
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/10 to-transparent" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-24">
                {KEY_FEATURES.map((feature, index) => (
                    <div key={index} className={`container mx-auto px-6 mb-24 ${index % 2 === 0 ? '' : 'lg:flex-row-reverse'}`}>
                        <div className="lg:flex items-center gap-12">
                            <div className="lg:w-1/2 mb-8 lg:mb-0">
                                <div className="flex items-center gap-3 mb-4">
                                    <div className="w-10 h-10 bg-blue-50 rounded-lg flex items-center justify-center">
                                        <feature.icon className="w-5 h-5 text-blue-600" />
                                    </div>
                                    <h2 className="text-2xl md:text-3xl font-bold">{feature.title}</h2>
                                </div>
                                
                                <p className="text-lg text-gray-600 mb-6">
                                    {feature.description}
                                </p>
                                
                                <ul className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-6">
                                    {feature.details.map((detail, i) => (
                                        <li key={i} className="flex items-center gap-2 text-gray-600">
                                            <HiOutlineCheck className="w-5 h-5 text-blue-500" />
                                            <span>{detail}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            
                            <div className="lg:w-1/2">
                                <div className="bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100">
                                    <video
                                        src={feature.video}
                                        className="w-full aspect-video object-cover"
                                        autoPlay
                                        muted
                                        loop
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="py-24 bg-gray-50">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-3xl md:text-4xl font-bold mb-12">Why Teams Love Our Platform</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="p-6">
                            <div className="text-4xl font-bold text-blue-600 mb-2">10x</div>
                            <p className="text-gray-600">Faster feedback cycles</p>
                        </div>
                        <div className="p-6">
                            <div className="text-4xl font-bold text-blue-600 mb-2">100%</div>
                            <p className="text-gray-600">Original quality preserved</p>
                        </div>
                        <div className="p-6">
                            <div className="text-4xl font-bold text-blue-600 mb-2">Unlimited</div>
                            <p className="text-gray-600">Team collaboration</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-24 bg-gray-50">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-3xl md:text-4xl font-bold mb-8">
                        Ready to Transform Your Video Review Process?
                    </h2>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                        <button
                            className="px-8 py-4 bg-primary text-white rounded-xl font-medium hover:opacity-90 transition-opacity shadow-lg shadow-blue-500/20"
                            onClick={() => navigate("/signup")}
                        >
                            Start Free Trial
                        </button>
                        <button className="px-8 py-4 bg-white rounded-xl font-medium text-gray-700 hover:bg-gray-50 transition-colors border border-gray-200 shadow-sm">
                            Schedule Demo
                        </button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default QuickReview;
