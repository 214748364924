import React, { useState, useCallback, MouseEvent as ReactMouseEvent, useEffect } from "react";

interface ResizablePanelsProps {
    children: React.ReactNode[];
    className?: string;
    defaultSizes?: number[];
    storageKey?: string;
    minSize?: number;
    onResize?: (newSizes: number[]) => void;
    isPlaying?: boolean;
    setIsPlaying?: (playing: boolean) => void;
}

export const ResizablePanels: React.FC<ResizablePanelsProps> = ({
    children,
    className = "",
    defaultSizes,
    storageKey,
    minSize = 0.1,
    onResize,
    isPlaying,
    setIsPlaying,
}) => {
    const [sizes, setSizes] = useState<number[]>(() => {
        if (storageKey) {
            const saved = localStorage.getItem(storageKey);
            if (saved) {
                const parsedSizes = JSON.parse(saved);
                if (
                    parsedSizes.length === React.Children.count(children) &&
                    parsedSizes.reduce((a: number, b: number) => a + b, 0) === 1
                ) {
                    return parsedSizes;
                }
            }
        }
        return (
            defaultSizes ||
            Array(React.Children.count(children)).fill(1 / React.Children.count(children))
        );
    });

    useEffect(() => {
        if (defaultSizes && defaultSizes.length === React.Children.count(children)) {
            setSizes(defaultSizes);
        } else {
            setSizes(
                Array(React.Children.count(children)).fill(1 / React.Children.count(children))
            );
        }
    }, [defaultSizes, children]);

    const [isDragging, setIsDragging] = useState<number | null>(null);
    const [startSizes, setStartSizes] = useState<number[]>([]);
    const [startX, setStartX] = useState<number>(0);

    const handleMouseDown = useCallback(
        (index: number, e: ReactMouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            setIsDragging(index);
            setStartSizes([...sizes]);
            setStartX(e.clientX);
            // Pause video when starting resize
            if (isPlaying && setIsPlaying) {
                setIsPlaying(false);
            }
        },
        [sizes, isPlaying, setIsPlaying]
    );

    React.useEffect(() => {
        const handleMouseMove = (e: globalThis.MouseEvent) => {
            if (isDragging === null) return;

            const container = document.getElementById("resizable-panels-container");
            if (!container) return;

            const containerRect = container.getBoundingClientRect();
            const deltaX = e.clientX - startX;
            const deltaPercentage = deltaX / containerRect.width;

            setSizes(prevSizes => {
                const newSizes = [...prevSizes];
                newSizes[isDragging] = startSizes[isDragging] + deltaPercentage;
                newSizes[isDragging + 1] = startSizes[isDragging + 1] - deltaPercentage;

                if (newSizes.some(size => size < minSize)) {
                    return prevSizes;
                }

                return newSizes;
            });
        };

        const handleMouseUp = () => {
            if (isDragging !== null) {
                setIsDragging(null);
                if (storageKey) {
                    localStorage.setItem(storageKey, JSON.stringify(sizes));
                }
                if (onResize) {
                    onResize(sizes);
                }
                // Resume video when resize is done
                if (setIsPlaying) {
                    setIsPlaying(true);
                }
            }
        };

        if (isDragging !== null) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging, startX, startSizes, sizes, storageKey, minSize, onResize, setIsPlaying]);
    const childCount = React.Children.count(children);
    if (childCount < 2) {
        return <div className={className}>{children}</div>;
    }

    return (
        <div
            id="resizable-panels-container"
            className={`flex w-full h-full overflow-hidden ${className}`}
        >
            {React.Children.map(children, (child, i) => (
                <div
                    key={i}
                    className="relative"
                    // style={{
                    //     width: `${sizes[i] * 100}%`,
                    //     transition: isDragging === null ? "width 0.1s ease-out" : "none",
                    // }}
                    style={{
                        width: `${sizes[i] * 100}%`,
                        transition: isDragging === null ? "none" : "none", // Remove transition to avoid weird animation
                    }}
                >
                    {child}
                    {i < childCount - 1 && (
                        <div
                            className={`absolute top-0 right-0 w-1 h-full cursor-col-resize group z-50
                            ${isDragging === i ? "bg-primary" : "hover:bg-primary/50 bg-base-300"}`}
                            onMouseDown={e => handleMouseDown(i, e)}
                        >
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 h-16 flex items-center justify-center">
                                <div className="flex flex-col gap-1">
                                    <div
                                        className={`w-0.5 h-0.5 rounded-full ${isDragging === i ? "bg-base-100" : "bg-base-content/50"}`}
                                    />
                                    <div
                                        className={`w-0.5 h-0.5 rounded-full ${isDragging === i ? "bg-base-100" : "bg-base-content/50"}`}
                                    />
                                    <div
                                        className={`w-0.5 h-0.5 rounded-full ${isDragging === i ? "bg-base-100" : "bg-base-content/50"}`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ResizablePanels;
