import { Video } from "../../VideoTile/DefaultVideoTile";

export const getFileSizeValue = (video: Video) => {
    if (!video.file_size) return 0;
    return parseInt(video.file_size, 10);
};

export const getFilteredVideos = (videos: Video[], filterQuery: string): Video[] => {
    if (!filterQuery) return videos;
    return videos.filter(video =>
        video.file_name.toLowerCase().includes(filterQuery.toLowerCase())
    );
};

export const getSortedVideos = (videos: Video[], sortBy: string): Video[] => {
    const videosCopy = [...videos];

    switch (sortBy) {
        case "newest":
            return videosCopy.sort(
                (a, b) => new Date(b.upload_date).getTime() - new Date(a.upload_date).getTime()
            );
        case "oldest":
            return videosCopy.sort(
                (a, b) => new Date(a.upload_date).getTime() - new Date(b.upload_date).getTime()
            );
        case "name":
            return videosCopy.sort((a, b) => a.file_name.localeCompare(b.file_name));
        case "duration-desc":
            return videosCopy.sort((a, b) => b.video_duration - a.video_duration);
        case "size-desc":
            return videosCopy.sort((a, b) => getFileSizeValue(b) - getFileSizeValue(a));
        default:
            return videosCopy;
    }
};
