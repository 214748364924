import React from "react";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes";

import Navbar from "./components/Navbar";

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes />
            </div>
        </Router>
    );
}

export default App;
