import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineDownload } from "react-icons/ai";
import { formatDate } from "../utils/date";

interface Notification {
    type: string;
    id: number;
    description: Description.DOWNLOAD;
    status: string;
    download_file?: string;
    read: boolean;
    created_at: string;
}

enum Description {
    DOWNLOAD = "[Clip] is ready for download",
}

interface NotificationDropdownProps {
    token: string | null;
}

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({ token }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchNotifications();

        const interval = setInterval(() => {
            fetchNotifications();
        }, 10000);

        return () => clearInterval(interval);
    }, [token]);

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/get_notifications`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNotifications(response.data);
            setUnreadCount(
                response.data.filter((notification: Notification) => !notification.read).length
            );
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    const getDescription = (type: string) => {
        switch (type) {
            case "DOWNLOAD_CLIP":
                return Description.DOWNLOAD;
            default:
                return "";
        }
    };

    const markAllAsRead = (event: React.MouseEvent<HTMLButtonElement>) => {
        notifications.map((notification, index) => {
            if (!notification.read) {
                handleRead(event, index);
            }
        });
    };

    const handleRead = async (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
        event.stopPropagation();
        try {
            const data = {
                id: notifications[id].id,
                type: "change_read",
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/handle_notification`,
                data,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status == 200) {
                const updatedNotifications = notifications.map((notification, index) =>
                    index === id ? { ...notification, read: !notification.read } : notification
                );
                setNotifications(updatedNotifications);
                setUnreadCount(prevCount => prevCount - 1);
            }
        } catch (error) {
            console.error("Error changing notification read status", error);
        }
    };

    const handleDownload = async (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
        const fileUrl = notifications[index].download_file;
        if (fileUrl) {
            const link = document.createElement("a");
            link.href = fileUrl;
            link.setAttribute("download", "true");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        event.stopPropagation();
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <div className="relative items-center" onClick={toggleDropdown}>
                <IoMdNotificationsOutline className="hover:scale-110 cursor-pointer" size={28} />
                {unreadCount > 0 && (
                    <span
                        className={`absolute top-0 right-0 flex items-center justify-center rounded-full bg-primary text-xs font-medium text-white ${unreadCount < 10 ? "h-4 w-4 p-2" : "h-4 w-auto px-1.5 py-0.5"}`}
                        style={{ transform: "translate(50%, -50%)" }}
                    >
                        {unreadCount}
                    </span>
                )}
            </div>

            {isOpen && (
                <div className="origin-top-right absolute right-0 rounded-2xl mt-2 w-[500px] h-[500px] overflow-scroll shadow-lg bg-white z-[100] outline outline-gray-200">
                    <div className="flex items-center justify-between p-4 mb-2 sticky top-0 bg-white border border-b-2-gray z-10">
                        <h1 className="text-2xl font-bold">Notifications</h1>
                        <button
                            className="btn btn-xs btn-ghost mt-auto"
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                markAllAsRead(event);
                            }}
                        >
                            Mark all as Read
                        </button>
                    </div>
                    <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {notifications.map((notification, index) => (
                            <div className={`items-start gap-4 p-3 z-6 border-b-2`} key={index}>
                                <div className="grid grid-cols-[30px_1fr_auto] items-start gap-4">
                                    <div
                                        className={`flex h-2 w-2 translate-y-1.5 rounded-full m-auto mt-[10px] ${!notification.read ? "bg-primary" : "bg-gray-400"}`}
                                    ></div>
                                    <div>
                                        <div className="flex-1">
                                            <p
                                                className={`text-sm ${!notification.read ? "font-semibold" : "font-normal"}`}
                                            >
                                                {getDescription(notification.type)}
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm text-gray-500 dark:text-gray-400 pt-2">
                                                {formatDate(notification.created_at)}
                                            </p>
                                            <div className="flex justify-end items-end gap-2 pt-2">
                                                {!notification.read && (
                                                    <form>
                                                        <button
                                                            type="button"
                                                            className="btn btn-xs btn-ghost"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                handleRead(e, index);
                                                            }}
                                                        >
                                                            Mark as Read
                                                        </button>
                                                    </form>
                                                )}
                                                <button
                                                    className="btn btn-xs btn-ghost"
                                                    onClick={e => handleDownload(index, e)}
                                                >
                                                    <AiOutlineDownload size={18} />
                                                    Download
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {notifications.length === 0 && (
                            <div className="flex justify-center items-center h-full">
                                <p>No notifications Yet!</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationDropdown;
