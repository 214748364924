import axios from "axios";
import { Video } from "../../VideoTile/DefaultVideoTile";

export const handleSubtitleDownload = async (subtitle_url: string) => {
    try {
        const response = await axios.get(subtitle_url, {
            responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "subtitle.vtt");
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error(error);
    }
};

export const handleVideoDownload = async (video_id: string, file_name: string, token: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API}/api/generate-download-url/${video_id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const downloadUrl = response.data.url;

        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = file_name;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    } catch (error) {
        console.error("Failed to generate download URL:", error);
    }
};

export const handleRetry = async (video: Video, token: string, onSuccess: () => void) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API}/api/retry-indexing`,
            {
                video_id: video.id,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.data.message) {
            onSuccess();
        }
    } catch (error) {
        console.error("Failed to retry video processing:", error);
        if (axios.isAxiosError(error) && error.response?.data?.error) {
            console.error(error.response.data.error);
        }
    }
};

export const deleteVideo = async (
    file_id: string,
    url: string,
    index_id: string,
    token: string,
    onSuccess: () => void
) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API}/api/delete_video`,
            {
                index_id,
                id: file_id,
                file_extension: url.split(".").pop(),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.data.message) {
            onSuccess();
        }
    } catch (error: any) {
        console.error(error);
    }
};
