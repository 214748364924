import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import logEvent from "../amplitude";
import { RiUserAddFill, RiMailFill, RiDeleteBin5Line, RiCloseFill } from "react-icons/ri";

interface Company {
    name: string;
    minutes_used: number;
    minutes_allotted: number;
    seats_allotted: number;
    seats_used: number;
}

interface User {
    first_name: string;
    last_name: string;
    email: string;
    verified: boolean;
    is_admin: boolean;
}

const CompanyDashboard: React.FC = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const currentUserEmail = localStorage.getItem("email"); // Assume the current user's email is stored in localStorage

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<Company>();
    const [users, setUsers] = useState<User[]>([]);
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
    const [addNewUserEmail, setAddNewUserEmail] = useState<string>("");
    const [userInvited, setUserInvited] = useState<boolean>(false);
    const [inviteError, setInviteError] = useState<string>("");
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertType, setAlertType] = useState<"success" | "error">("success");
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        logEvent("page_view", {
            page_title: "Company Dashboard",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });

        if (!token) {
            navigate("/login");
        }

        axios
            .get(`${process.env.REACT_APP_API}/api/company/company_details`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                const companyDetails = response.data.company_details;
                const usersList = response.data.users;
                const currentUser = usersList.find((user: User) => user.email === currentUserEmail);

                setCompany(companyDetails);
                setUsers(usersList);
                setCurrentUserIsAdmin(currentUser?.is_admin || false);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setInviteError("Something went wrong. Please try again.");
            });
    }, [token, navigate, currentUserEmail]);

    const handleAddNewUser = () => {
        logEvent("button_click", {
            button_name: "Add New User",
            button_location: "Company Dashboard",
        });

        if (addNewUserEmail) {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(addNewUserEmail)) {
                setInviteError("Invalid email address");
                return;
            }

            setInviteError("");
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/company/invite_users`,
                    {
                        emails: [addNewUserEmail],
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then(response => {
                    setUserInvited(true);
                    setAddNewUserEmail("");
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    const handleResendInvite = (email: string) => {
        axios
            .post(
                `${process.env.REACT_APP_API}/api/company/resend_invite`,
                { email },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(response => {
                setAlertMessage("Verification email sent successfully.");
                setAlertType("success");
                setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
            })
            .catch(error => {
                console.error(error);
                setAlertMessage("Failed to resend verification email.");
                setAlertType("error");
                setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
            });
    };

    const handleRemoveUser = (user: User) => {
        setUserToDelete(user);
        setShowDeleteUserModal(true);
    };

    const confirmRemoveUser = () => {
        if (userToDelete) {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/company/delete_user`,
                    { email: userToDelete.email },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then(response => {
                    setUsers(users.filter(user => user.email !== userToDelete.email));
                    setAlertMessage("User removed successfully.");
                    setAlertType("success");
                    setUserToDelete(null);
                    setShowDeleteUserModal(false);
                    setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
                })
                .catch(error => {
                    console.error(error);
                    setAlertMessage("Failed to remove user.");
                    setAlertType("error");
                    setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
                });
        }
    };

    const verifiedUsers = users.filter(user => user.verified);
    const unverifiedUsers = users.filter(user => !user.verified);

    return (
        <div className="relative flex flex-col h-screen px-24 mb-20">
            {alertMessage && (
                <div className="fixed top-0 left-0 right-0 flex justify-center items-center mt-20">
                    <div
                        className={`${
                            alertType === "success"
                                ? "bg-green-200 border-green-400 text-green-700"
                                : "bg-red-200 border-red-400 text-red-700"
                        } px-4 py-3 rounded relative flex flex-row`}
                        role="alert"
                    >
                        <span className="block sm:inline">{alertMessage}</span>
                        <RiCloseFill
                            className={`h-6 w-6 ${alertType === "success" ? "text-green-500" : "text-red-500"} cursor-pointer`}
                            aria-hidden="true"
                            onClick={() => setAlertMessage("")}
                        />
                    </div>
                </div>
            )}

            {isLoading ? (
                <div className="flex justify-center items-center h-96">
                    <span className="loading loading-spinner loading-lg text-primary"></span>
                </div>
            ) : (
                <div>
                    <p className="text-3xl font-bold">Company Dashboard</p>
                    <div className="mt-4 flex flex-col gap-4">
                        <p className="text-lg">Name: {company?.name}</p>
                        <div className="flex flex-row gap-2 items-center">
                            <p>Usage:</p>
                            <progress
                                className="progress progress-primary w-56"
                                value={company?.minutes_used}
                                max={company?.minutes_allotted}
                            ></progress>
                            <p className="text-sm font-bold">
                                {company?.minutes_used} / {company?.minutes_allotted} minutes
                            </p>
                        </div>
                        <p className="text-lg">
                            Seats: {company?.seats_used} / {company?.seats_allotted} used
                        </p>
                    </div>

                    <div className="mt-10">
                        <div className="flex flex-row justify-between">
                            <p className="font-bold text-2xl mb-4">Manage Users</p>
                            <div>
                                {currentUserIsAdmin ? (
                                    <button
                                        className="btn btn-primary text-white"
                                        onClick={() => setShowAddUserModal(true)}
                                    >
                                        <RiUserAddFill />
                                        Add User
                                    </button>
                                ) : (
                                    <div
                                        className="tooltip"
                                        data-tip="Only admins are allowed to add users"
                                    >
                                        <span>
                                            <button
                                                className="btn btn-primary text-white cursor-not-allowed"
                                                disabled
                                            >
                                                <RiUserAddFill />
                                                Add User
                                            </button>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="overflow-x-auto">
                            <table className="table table-zebra">
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th className="px-4 py-2">Verified</th>
                                        <th className="px-4 py-2">Admin</th>
                                        <th className="px-4 py-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {verifiedUsers.map(user => (
                                        <tr key={user.email}>
                                            <td>{user.first_name}</td>
                                            <td>{user.last_name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.verified ? "Verified" : "Not Verified"}</td>
                                            <td>{user.is_admin ? "True" : "False"}</td>
                                            <td>
                                                {user.email !== currentUserEmail ? (
                                                    currentUserIsAdmin ? (
                                                        <button
                                                            className="btn btn-sm bg-red-500 hover:bg-red-600 text-white"
                                                            onClick={() => handleRemoveUser(user)}
                                                        >
                                                            <RiDeleteBin5Line />
                                                            Remove
                                                        </button>
                                                    ) : (
                                                        <div
                                                            className="tooltip"
                                                            data-tip="Only admins are allowed to delete"
                                                        >
                                                            <span>
                                                                <button
                                                                    className="btn btn-sm bg-red-500 text-white cursor-not-allowed"
                                                                    disabled
                                                                >
                                                                    <RiDeleteBin5Line />
                                                                    Remove
                                                                </button>
                                                            </span>
                                                        </div>
                                                    )
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mt-10">
                        <p className="font-bold text-2xl mb-4">Invited Users</p>
                        <div className="overflow-x-auto">
                            <table className="table table-zebra">
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th className="px-4 py-2">Verified</th>
                                        <th className="px-4 py-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unverifiedUsers.map(user => (
                                        <tr key={user.email}>
                                            <td>{user.email}</td>
                                            <td>{user.verified ? "Verified" : "Not Verified"}</td>
                                            <td>
                                                <button
                                                    className="btn btn-sm bg-blue-500 hover:bg-blue-600 text-white"
                                                    onClick={() => handleResendInvite(user.email)}
                                                >
                                                    <RiMailFill />
                                                    Resend Invite
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {showAddUserModal && (
                <Modal
                    isOpen={showAddUserModal}
                    onClose={() => {
                        setShowAddUserModal(false);
                        setUserInvited(false);
                    }}
                    title="Invite user to your workspace"
                >
                    <div className="flex flex-col gap-4">
                        {userInvited ? (
                            <p>
                                User has been sent an invitation email. Please ask them to verify
                                their email.
                            </p>
                        ) : (
                            <div>
                                {inviteError && <p className="text-red-500">{inviteError}</p>}
                                <label className="form-control w-full max-w-xs">
                                    <div className="label">
                                        <span className="label-text">
                                            What is your teammate's email?
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Type here"
                                        className="input input-bordered focus:outline-none w-full max-w-xs"
                                        value={addNewUserEmail}
                                        onChange={e => setAddNewUserEmail(e.target.value)}
                                    />
                                </label>

                                <div>
                                    <button
                                        className="btn btn-primary text-white w-32 mt-4"
                                        onClick={handleAddNewUser}
                                    >
                                        Invite
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal>
            )}

            {showDeleteUserModal && (
                <Modal
                    isOpen={showDeleteUserModal}
                    onClose={() => {
                        setShowDeleteUserModal(false);
                        setUserToDelete(null);
                    }}
                    title="Confirm User Deletion"
                >
                    <div className="flex flex-col gap-4">
                        <p>Are you sure you want to remove {userToDelete?.email}?</p>
                        <div className="flex justify-end gap-4">
                            <button
                                className="btn btn-ghost"
                                onClick={() => {
                                    setShowDeleteUserModal(false);
                                    setUserToDelete(null);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn bg-red-500 text-white"
                                onClick={confirmRemoveUser}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default CompanyDashboard;
