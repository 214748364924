import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import icon from "../assets/logo/icon.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import logEvent from "../amplitude";

interface OnboardFormData {
    first_name: string;
    last_name: string;
    password: string;
    confirm_password: string;
}

const InvitedUserOnboarding: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const token = queryParams.get("token");

    const [formData, setFormData] = useState<OnboardFormData>({
        first_name: "",
        last_name: "",
        password: "",
        confirm_password: "",
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<Partial<OnboardFormData>>({});
    const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Invited User Onboarding",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API}/api/company/is_token_valid`,
                    {
                        email,
                        token,
                    }
                );
                setIsTokenValid(response.data.is_valid);
                if (!response.data.is_valid) {
                    setErrorMessage(
                        "Invalid or expired token. Please contact your workspace admin for a new invite."
                    );
                }
            } catch (error) {
                console.error("Token validation error:", error);
                setIsTokenValid(false);
                setErrorMessage(
                    "Invalid or expired token. Please contact your workspace admin for a new invite."
                );
            }
        };

        if (email && token) {
            validateToken();
        }
    }, [email, token]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors: Partial<OnboardFormData> = {};

        if (formData.first_name.trim() === "") {
            newErrors.first_name = "First Name is required";
        }
        if (formData.last_name.trim() === "") {
            newErrors.last_name = "Last Name is required";
        }
        if (formData.password.trim() === "") {
            newErrors.password = "Password is required";
        } else if (formData.password.length < 8) {
            newErrors.password = "Password must be at least 8 characters long";
        }
        if (formData.confirm_password.trim() === "") {
            newErrors.confirm_password = "Confirm Password is required";
        } else if (formData.password !== formData.confirm_password) {
            newErrors.confirm_password = "Passwords do not match";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleOnboarding = async () => {
        if (validateForm()) {
            setIsLoading(true);

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API}/api/user/finish_onboarding`,
                    {
                        email,
                        token,
                        first_name: formData.first_name,
                        last_name: formData.last_name,
                        password: formData.password,
                    }
                );

                if (response.status === 200) {
                    navigate("/login");
                } else {
                    setErrorMessage(response.data.message);
                }
            } catch (error: any) {
                console.error("Error during onboarding:", error);
                setErrorMessage("An error occurred during onboarding. Please try again.");
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (!isTokenValid) {
        return (
            <div className="flex justify-center items-center mt-[100px]">
                <p className="text-red-500">{errorMessage}</p>
            </div>
        );
    }

    return (
        <div className="flex justify-center items-center mt-[100px]">
            <div className="flex flex-col text-center w-[500px] rounded-lg outline outline-1 outline-gray-300 p-8">
                <div className="flex justify-center">
                    <img src={icon} alt="logo" className="h-12 md:h-20 mb-2" />
                </div>
                <p className="text-2xl font-bold mb-10">Welcome to QuickSight</p>

                {errorMessage && <div className="mb-4 text-red-500">{errorMessage}</div>}

                <div className="mb-4">
                    <p>Please enter the following information to finish onboarding</p>
                </div>

                <div className="mb-4">
                    <p className="flex pl-1 pb-1">Email</p>
                    <input
                        type="email"
                        value={email || ""}
                        disabled
                        className="input input-bordered focus:outline-none w-full bg-gray-100 cursor-not-allowed"
                    />
                </div>

                <div className="flex flex-row justify-between mb-4">
                    <div className="w-[48%]">
                        <p className="flex pl-1 pb-1">First Name</p>
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            className={`input input-bordered focus:outline-none w-full ${
                                errors.first_name ? "border-red-500" : ""
                            }`}
                            onChange={handleInputChange}
                        />
                        {errors.first_name && (
                            <p className="text-red-500 text-xs italic text-left mt-1">
                                {errors.first_name}
                            </p>
                        )}
                    </div>

                    <div className="w-[48%]">
                        <p className="flex pl-1 pb-1">Last Name</p>
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            className={`input input-bordered focus:outline-none w-full ${
                                errors.last_name ? "border-red-500" : ""
                            }`}
                            onChange={handleInputChange}
                        />
                        {errors.last_name && (
                            <p className="text-red-500 text-xs italic text-left mt-1">
                                {errors.last_name}
                            </p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="password" className="flex pl-1 pb-1">
                        Password
                    </label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Password"
                            required
                            className={`input input-bordered focus:outline-none w-full pr-10 ${
                                errors.password ? "border-red-500" : ""
                            }`}
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        <button
                            type="button"
                            className="absolute inset-y-0 right-0 mr-3 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaRegEyeSlash /> : <MdOutlineRemoveRedEye />}
                        </button>
                    </div>
                    {errors.password && (
                        <p className="text-red-500 text-xs italic text-left mt-1">
                            {errors.password}
                        </p>
                    )}
                </div>

                <div className="mb-4">
                    <label htmlFor="confirm_password" className="flex pl-1 pb-1">
                        Confirm Password
                    </label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            required
                            className={`input input-bordered focus:outline-none w-full pr-10 ${
                                errors.confirm_password ? "border-red-500" : ""
                            }`}
                            value={formData.confirm_password}
                            onChange={handleInputChange}
                        />
                    </div>
                    {errors.confirm_password && (
                        <p className="text-red-500 text-xs italic text-left mt-1">
                            {errors.confirm_password}
                        </p>
                    )}
                </div>

                <button
                    className="btn btn-primary mt-4 bg-primary text-white"
                    onClick={handleOnboarding}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <span className="loading loading-spinner loading-xs">Loading...</span>
                    ) : (
                        "Complete Onboarding"
                    )}
                </button>
            </div>
        </div>
    );
};

export default InvitedUserOnboarding;
