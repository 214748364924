import React, { useEffect, useState } from "react";
import logEvent from "../amplitude";

const About: React.FC = () => {
    useEffect(() => {
        logEvent("page_view", {
            page: "about",
        });
    });

    return (
        <div className="flex justify-center items-center mt-[100px] p-4">
            <div className="flex flex-col text-center w-[400px] outline outline-1 outline-gray-300 p-8 rounded-lg">
                <h1 className="text-4xl font-bold mb-4">About</h1>
                <p className="text-lg">
                    Please reach out to abhinav@quicksight.ai for any questions or support!
                </p>
            </div>
        </div>
    );
};

export default About;
