import React, { useEffect, ReactNode } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface ToastProps {
    message: string;
    icon?: ReactNode;
    show: boolean;
    onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, icon, show, onClose }) => {
    useEffect(() => {
        let toastTimer: NodeJS.Timeout;

        if (show) {
            toastTimer = setTimeout(() => {
                onClose();
            }, 3000);
        }

        return () => {
            clearTimeout(toastTimer);
        };
    }, [show, onClose]);

    if (!show) {
        return null;
    }

    return (
        <div className="fixed top-0 right-0 z-50 m-4">
            <div className="toast toast-end">
                <div className="alert bg-white shadow-2xl">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            {icon && <div className="mr-2">{icon}</div>}
                            <span>{message}</span>
                        </div>
                        <button className="btn btn-ghost btn-sm ml-4" onClick={onClose}>
                            <AiOutlineClose size={18} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toast;
