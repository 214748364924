import React, { useState, useEffect } from "react";
import axios from "axios";
import logEvent from "../amplitude";

const ChangePassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Change Password",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        };

        if (currentPassword.trim() === "") {
            newErrors.currentPassword = "Current password is required";
            isValid = false;
        }

        if (newPassword.trim() === "") {
            newErrors.newPassword = "New password is required";
            isValid = false;
        } else if (newPassword.length < 6) {
            newErrors.newPassword = "New password must be at least 6 characters long";
            isValid = false;
        }

        if (confirmPassword.trim() === "") {
            newErrors.confirmPassword = "Confirm password is required";
            isValid = false;
        } else if (confirmPassword !== newPassword) {
            newErrors.confirmPassword = "Passwords do not match";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setLoading(true);
                setError("");
                const response = await axios.post(
                    `${process.env.REACT_APP_API}/api/change-password`,
                    {
                        old_password: currentPassword,
                        new_password: newPassword,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                setSuccess(true);
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
            } catch (error: any) {
                setError(error.response.data.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="flex justify-center mt-28 min-h-screen">
            <div className="card w-96 h-full bg-base-100 outline outline-gray-200">
                <div className="card-body">
                    <h2 className="card-title">Change Password</h2>
                    {success && (
                        <div className="text-green-500">Password changed successfully!</div>
                    )}
                    {error && <div className="alert alert-error">{error}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Current Password</span>
                            </label>
                            <input
                                type="password"
                                value={currentPassword}
                                onChange={e => setCurrentPassword(e.target.value)}
                                className={`input input-bordered focus-within:outline-none ${
                                    errors.currentPassword && "border-error"
                                }`}
                                required
                            />
                            {errors.currentPassword && (
                                <span className="text-error">{errors.currentPassword}</span>
                            )}
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">New Password</span>
                            </label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                className={`input input-bordered focus-within:outline-none ${
                                    errors.newPassword && "border-error"
                                }`}
                                required
                            />
                            {errors.newPassword && (
                                <span className="text-error">{errors.newPassword}</span>
                            )}
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Confirm Password</span>
                            </label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                className={`input input-bordered focus-within:outline-none ${
                                    errors.confirmPassword && "border-error"
                                }`}
                                required
                            />
                            {errors.confirmPassword && (
                                <span className="text-error">{errors.confirmPassword}</span>
                            )}
                        </div>
                        <div className="form-control mt-6 flex justify-center">
                            <button
                                type="submit"
                                className={`btn btn-primary text-white ${loading && "loading"}`}
                                disabled={loading}
                            >
                                {loading ? "Changing Password..." : "Change Password"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
