import React from "react";
import { HiOutlineMail, HiOutlinePhone, HiOutlineGlobe } from "react-icons/hi";

const Footer: React.FC = () => (
    <footer className="bg-white border-t border-gray-200">
        <div className="container mx-auto px-6 py-12">
            <div className="grid md:grid-cols-4 gap-8">
                <div className="md:col-span-1">
                    <h3 className="font-bold text-gray-900 mb-4">Quicksight AI</h3>
                    <p className="text-gray-600 text-sm">
                        Making video search and collaboration as easy as text search.
                    </p>
                </div>

                <div>
                    <h4 className="font-medium text-gray-900 mb-4">Products</h4>
                    <ul className="space-y-2 text-sm text-gray-600">
                        <li>
                            <a href="/quick-search" className="hover:text-purple-600 cursor-pointer">
                                Video Search
                            </a>
                        </li>
                        <li>
                            <a href="/quick-review" className="hover:text-purple-600 cursor-pointer">
                                QuickReview
                            </a>
                        </li>
                        <li>
                            <a href="/quick-gen" className="hover:text-purple-600 cursor-pointer">
                                QuickGen (Soon)
                            </a>
                        </li>
                        {/* <li className="hover:text-purple-600 cursor-pointer">Enterprise</li> */}
                    </ul>
                </div>

                <div>
                    <h4 className="font-medium text-gray-900 mb-4">Company</h4>
                    <ul className="space-y-2 text-sm text-gray-600">
                        <li className="hover:text-purple-600 cursor-pointer">About</li>
                        <li className="hover:text-purple-600 cursor-pointer">Blog</li>
                        <li className="hover:text-purple-600 cursor-pointer">Careers</li>
                        <li className="hover:text-purple-600 cursor-pointer">Contact</li>
                    </ul>
                </div>

                <div>
                    <h4 className="font-medium text-gray-900 mb-4">Contact</h4>
                    <ul className="space-y-3 text-sm text-gray-600">
                        <li className="flex items-center gap-2">
                            <HiOutlineMail className="w-4 h-4" />
                            <a href="mailto:hello@quickai.com" className="hover:text-purple-600">
                                abhinav@quicksight.ai
                            </a>
                        </li>
                        <li className="flex items-center gap-2">
                            <HiOutlinePhone className="w-4 h-4" />
                            <a href="tel:+1234567890" className="hover:text-purple-600">
                                (510) 224-5801
                            </a>
                        </li>
                        <li className="flex items-center gap-2">
                            <HiOutlineGlobe className="w-4 h-4" />
                            <a href="https://quicksight.ai" className="hover:text-purple-600">
                                quicksight.ai
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="mt-12 pt-8 border-t border-gray-200">
                <div className="grid md:grid-cols-2 gap-4 items-center">
                    <div className="text-sm text-gray-600">
                        © {new Date().getFullYear()} Quicksight, Inc. All rights reserved.
                    </div>
                    <div className="flex gap-6 justify-start md:justify-end text-sm text-gray-600">
                        <a href="#" className="hover:text-purple-600">
                            Privacy Policy
                        </a>
                        <a href="#" className="hover:text-purple-600">
                            Terms of Service
                        </a>
                        <a href="#" className="hover:text-purple-600">
                            Cookie Policy
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
