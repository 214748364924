import React, { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { AiOutlineClose } from "react-icons/ai";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    title?: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, title }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [isOpen]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [onClose]);

    useEffect(() => {
        const modalElement = modalRef.current;

        if (isOpen && modalElement) {
            setTimeout(() => {
                modalElement.classList.remove("opacity-0");
                modalElement.classList.remove("translate-y-8");
                modalElement.classList.add("opacity-100");
                modalElement.classList.add("translate-y-0");
            }, 10);
        } else if (!isOpen && modalElement) {
            modalElement.classList.remove("opacity-100");
            modalElement.classList.remove("translate-y-0");
            modalElement.classList.add("opacity-0");
            modalElement.classList.add("translate-y-8");
        }
    }, [isOpen]);

    return isOpen
        ? createPortal(
              <>
                  <div className="fixed z-[100] inset-0 overflow-y-auto">
                      <div className="flex items-center justify-center min-h-screen">
                          <div
                              className="fixed inset-0 transition-opacity bg-gray-500 opacity-75"
                              onClick={onClose}
                          ></div>

                          <div
                              className="bg-white overflow-hidden shadow-xl transition-all transform opacity-0 translate-y-8 w-[50%]"
                              ref={modalRef}
                          >
                              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex w-[100%] justify-between">
                                  <div className="sm:flex sm:items-start">
                                      {title && (
                                          <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                              <h3
                                                  className="text-lg leading-6 font-medium text-gray-900"
                                                  id="modal-title"
                                              >
                                                  {title}
                                              </h3>
                                          </div>
                                      )}
                                  </div>
                                  <div className="justify-end">
                                      <AiOutlineClose
                                          className="text-2xl ml-auto cursor-pointer hover:bg-slate-100 rounded-full p-1"
                                          onClick={onClose}
                                      />
                                  </div>
                              </div>
                              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">{children}</div>
                          </div>
                      </div>
                  </div>
              </>,
              document.body
          )
        : null;
};

export default Modal;
