import React, { useRef, useEffect, useState } from "react";

interface DropdownItem {
    label: string;
    icon?: React.ReactNode;
    onClick: () => void;
    className?: string;
}

interface DropdownProps {
    trigger: React.ReactNode;
    items: DropdownItem[];
    align?: "left" | "right";
    className?: string;
    triggerClassName?: string;
    menuClassName?: string;
    itemClassName?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
    trigger,
    items,
    align = "left",
    className = "",
    triggerClassName = "",
    menuClassName = "",
    itemClassName = "",
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleItemClick = (onClick: () => void) => {
        onClick();
        setIsOpen(false);
    };

    return (
        <div className={`relative inline-block ${className}`} ref={dropdownRef}>
            <button onClick={() => setIsOpen(!isOpen)} className={triggerClassName}>
                {trigger}
            </button>

            <div
                className={`
          absolute z-50 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
          transition-all duration-200 ease-in-out origin-top-right
          ${align === "right" ? "right-0" : "left-0"}
          ${isOpen ? "opacity-100 scale-100" : "opacity-0 scale-95 pointer-events-none"}
          ${menuClassName}
        `}
            >
                <div className="py-1">
                    {items.map(item => (
                        <button
                            key={item.label}
                            onClick={() => handleItemClick(item.onClick)}
                            className={`
                w-full text-left px-4 py-2 text-sm
                hover:bg-gray-100
                flex items-center gap-2
                ${item.className || ""}
                ${itemClassName}
              `}
                        >
                            {item.icon}
                            {item.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
