import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
    AiOutlineSearch,
    AiOutlineDownload,
    AiOutlineQuestionCircle,
    AiOutlineCheckCircle,
    AiOutlineClose,
} from "react-icons/ai";
import { MdImageSearch } from "react-icons/md";
import Modal from "../components/Modal";
import SearchVideoPlayer from "../components/VideoPlayer/SearchVideoPlayer";
import ReactPlayer from "react-player";
import VideoTile, { Video } from "../components/VideoTile";
import DefaultVideoTile from "../components/VideoTile/DefaultVideoTile";
import { handleSubtitleDownload } from "../components/CollectionDetail/utils/videoUtils";
import Toast from "../components/Toast";

import { parseDuration } from "../components/utils/duration";
import { debounce } from "lodash";

import logEvent from "../amplitude";

interface VideoMatchResponse {
    matches: Video[];
    index_id: string;
}

enum SearchOptions {
    visual = "VISUAL",
    text = "TEXT_IN_VIDEO",
    conversation = "CONVERSATION",
}

enum Confidence {
    low = "LOW",
    medium = "MEDIUM",
    high = "HIGH",
}

export enum Score {
    low = 0,
    medium = 0.77,
    high = 0.83,
}

const MIN_DESIRED_RESULTS = 6;

const temperature_description =
    "A lower temperature will return fewer but potentially more accurate results, while a higher temperature will return more diverse results";

const Search: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [allSearchResults, setAllSearchResults] = useState<VideoMatchResponse | undefined>(
        undefined
    );
    const [searchResults, setSearchResults] = useState<VideoMatchResponse | undefined>(undefined);
    const [temperature, setTemperature] = useState<number>(0.5);
    const [searchOptions, setSearchOptions] = useState<SearchOptions[]>([
        SearchOptions.visual,
        SearchOptions.text,
        SearchOptions.conversation,
    ]);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedVideo, setSelectedVideo] = useState<Video | undefined>(undefined);
    const [collectionDetails, setCollectionDetails] = useState<any>();
    const [collectionList, setCollectionList] = useState<any>();

    const [confidence, setConfidence] = useState<Confidence>(Confidence.high);

    const [isDefaultVideoModalOpen, setIsDefaultVideoModalOpen] = useState<boolean>(false);
    const [defaultSelectedVideo, setDefaultSelectedVideo] = useState<Video | undefined>(undefined);

    const [showDownloadToast, setShowDownloadToast] = useState<boolean>(false);

    const [isAddImageModalOpen, setIsAddImageModalOpen] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>("");
    const [imageFile, setImageFile] = useState<File | null>(null);

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Search",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        fetchCollectionDetails();
        fetchCollectionList();
    }, [id]);

    useEffect(() => {
        if (searchQuery.length > 0) {
            handleSearch();
        } else if (imageFile || imageUrl) {
            handleImageSearch();
        }
    }, [searchOptions, temperature]);

    const fetchCollectionDetails = async () => {
        if (id) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/api/indexes/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setCollectionDetails(response.data.videos);
            } catch (error) {
                console.error("Error fetching collection details:", error);
            }
        }
    };

    const fetchCollectionList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/indexes`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCollectionList(response.data);
        } catch (error) {
            console.error("Error fetching collection list:", error);
        }
    };

    const handleSearch = async () => {
        if (imageFile || imageUrl) {
            await handleImageSearch();
            return;
        }

        setSearchResults(undefined);
        setAllSearchResults(undefined);
        setIsSearching(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API}/api/query_index?index_id=${id}&query=${searchQuery}&search_type=${searchOptions.join(
                    ","
                )}&temperature=${temperature}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            const allResults = response.data;
            setAllSearchResults(allResults);

            const filteredResults = filterResultsByConfidence(allResults.matches, confidence);
            setSearchResults({ ...allResults, matches: filteredResults });
        } catch (error) {
            console.error("Error performing search:", error);
        }

        setIsSearching(false);
    };

    const filterResultsByConfidence = (matches: Video[], currentConfidence: Confidence) => {
        const highResults = matches.filter(result => result.score >= Score.high);
        const mediumResults = matches.filter(result => result.score >= Score.medium);
        const lowResults = matches.filter(result => result.score >= Score.low);

        if (highResults.length >= MIN_DESIRED_RESULTS) {
            setConfidence(Confidence.high);
            return highResults;
        } else if (mediumResults.length >= MIN_DESIRED_RESULTS) {
            setConfidence(Confidence.medium);
            return mediumResults;
        } else {
            setConfidence(Confidence.low);
            return lowResults;
        }
    };

    const handleDownload = async (
        start_time: number | undefined,
        end_time: number | undefined,
        video_id: string | undefined
    ) => {
        try {
            const data = {
                start_time: start_time,
                end_time: end_time,
                video_id: video_id,
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/download_clip`,
                data,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200) {
                setShowDownloadToast(true);
            } else {
                console.error("Error performing search:", response.data);
            }
        } catch (error) {
            console.error("Error performing download:", error);
        }
    };

    const handleModalShow = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleDefaultVideoModalShow = () => {
        setIsDefaultVideoModalOpen(!isDefaultVideoModalOpen);
    };

    const handleVideoSelect = (video: Video) => {
        setSelectedVideo(video);
        handleModalShow();
    };

    const handleDefaultVideoSelect = (video: Video) => {
        setDefaultSelectedVideo(video);
        handleDefaultVideoModalShow();
    };

    const handleSearchOptionChange = (option: SearchOptions) => {
        setSearchOptions(prevOptions =>
            prevOptions.includes(option)
                ? prevOptions.filter(opt => opt !== option)
                : [...prevOptions, option]
        );
    };

    const handleCollectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCollectionId = event.target.value;
        navigate(`/collections/${selectedCollectionId}/search`);
    };

    const renderResults = () => {
        if (isSearching) {
            return (
                <div className="flex justify-center">
                    <span className="loading loading-spinner loading-lg text-primary"></span>
                </div>
            );
        }

        if (!searchResults) {
            return (
                <div className="flex">
                    <div>
                        {collectionDetails ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                                {collectionDetails.map((video: any) => (
                                    <DefaultVideoTile
                                        key={video.id}
                                        video={video}
                                        handleVideoSelect={() => handleDefaultVideoSelect(video)}
                                        handleSubtitleDownload={() =>
                                            handleSubtitleDownload(video.subtitle_url)
                                        }
                                        handleVideoDownload={() =>
                                            handleDownload(0, video.duration, video.id)
                                        }
                                        handleVideoDelete={() =>
                                            deleteVideo(video.id, video.file_url)
                                        }
                                    />
                                ))}
                            </div>
                        ) : (
                            <p>Please upload videos to search.</p>
                        )}
                    </div>
                </div>
            );
        }

        if (searchResults.matches.length === 0) {
            return (
                <div className="flex justify-center w-full">
                    <p className="text-center">No results found</p>
                </div>
            );
        }

        return (
            <div className="flex flex-wrap gap-10">
                {searchResults.matches.map(result => (
                    <div
                        key={result.video_id + result.start_time}
                        className="flex flex-col items-center cursor-pointer"
                        onClick={() => handleVideoSelect(result)}
                    >
                        <VideoTile video={result} />
                    </div>
                ))}
            </div>
        );
    };

    const deleteVideo = async (file_id: string, url: string) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API}/api/delete_video`,
                {
                    index_id: id,
                    id: file_id,
                    file_extension: url.split(".").pop(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } catch (error: any) {
            console.error(error);
        }
    };

    const handleTemperatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const temperature = parseFloat(event.target.value);
        setTemperature(temperature);
        console.log(temperature);
    };

    const handleConfidenceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newConfidence = event.target.value as Confidence;
        setConfidence(newConfidence);

        if (allSearchResults) {
            const filteredResults = allSearchResults.matches.filter(result => {
                if (newConfidence === Confidence.high) {
                    return result.score >= Score.high;
                } else if (newConfidence === Confidence.medium) {
                    return result.score >= Score.medium;
                } else {
                    return result.score >= Score.low;
                }
            });
            setSearchResults({ ...allSearchResults, matches: filteredResults });
        }
    };

    const handleAddImageModalShow = () => {
        setIsAddImageModalOpen(!isAddImageModalOpen);
    };

    const handleImageUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImageUrl(event.target.value);
    };

    const handleImageFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImageFile(event.target.files[0]);
        }
    };

    const handleImageRemove = () => {
        setImageUrl("");
        setImageFile(null);
    };

    const handleImageSearch = async () => {
        setSearchResults(undefined);
        setAllSearchResults(undefined);
        setIsSearching(true);

        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile);
        } else if (imageUrl) {
            formData.append("image_url", imageUrl);
        } else {
            console.error("No image file or URL provided");
            setIsSearching(false);
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/image_search?index_id=${id}&temperature=${temperature}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            const allResults = response.data;
            setAllSearchResults(allResults);

            const filteredResults = filterResultsByConfidence(allResults.matches, confidence);
            setSearchResults({ ...allResults, matches: filteredResults });
        } catch (error) {
            console.error("Error performing image search:", error);
        }

        setIsSearching(false);
    };

    return (
        <div className="App h-screen justify-center px-24">
            <div className="text-left w-full pb-10">
                <h1 className="text-4xl font-bold mb-4">Search</h1>
                <p>Search for key moments, text, conversation, events, and scenes in videos.</p>
            </div>
            <div className="flex gap-8 h-full">
                <div className="w-[20%]">
                    <label className="form-control w-full mb-12">
                        <div className="label">
                            <span className="text-sm font-medium">Choose collection</span>
                        </div>
                        <select
                            className="select select-bordered focus-within:outline-none"
                            value={id}
                            onChange={handleCollectionChange}
                        >
                            {collectionList?.indexes.map((collection: any) => (
                                <option key={collection.id} value={collection.id}>
                                    {collection.name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <div className="mb-12">
                        <p className="text-md font-medium mb-4">Select search options</p>
                        <div className="form-control max-w-fit">
                            <label className="label cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={searchOptions.includes(SearchOptions.visual)}
                                    onChange={() => handleSearchOptionChange(SearchOptions.visual)}
                                    className="checkbox checkbox-sm"
                                />
                                <span className="label-text pl-5">Visual</span>
                            </label>
                        </div>
                        <div className="form-control max-w-fit">
                            <label className="label cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={searchOptions.includes(SearchOptions.text)}
                                    onChange={() => handleSearchOptionChange(SearchOptions.text)}
                                    className="checkbox checkbox-sm"
                                />
                                <span className="label-text pl-5">Text in video</span>
                            </label>
                        </div>
                        <div className="form-control max-w-fit">
                            <label className="label cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={searchOptions.includes(SearchOptions.conversation)}
                                    onChange={() =>
                                        handleSearchOptionChange(SearchOptions.conversation)
                                    }
                                    className="checkbox checkbox-sm"
                                />
                                <span className="label-text pl-5">Conversation</span>
                            </label>
                        </div>
                    </div>

                    <label className="form-control w-full mb-12">
                        <div className="label">
                            <span className="text-sm font-medium">Minimum confidence level</span>
                        </div>
                        <select
                            className="select select-bordered focus-within:outline-none"
                            value={confidence}
                            onChange={handleConfidenceChange}
                        >
                            <option value={Confidence.high}>High</option>
                            <option value={Confidence.medium}>Medium</option>
                            <option value={Confidence.low}>Low</option>
                        </select>
                    </label>

                    {/* <div className="form-control w-full mb-14">
                        <label className="label justify-start gap-2">
                            <span className="text-sm font-medium">Temperature</span>
                            <div className="tooltip" data-tip={temperature_description}>
                                <AiOutlineQuestionCircle size={14} />
                            </div>
                        </label>
                        <input
                            type="range"
                            min={0}
                            max="1"
                            step="0.1"
                            defaultValue={temperature}
                            className="range range-xs"
                            onChange={debounce(handleTemperatureChange, 1000)}
                        />
                    </div> */}
                </div>
                <div className="divider lg:divider-horizontal"></div>
                <div className="w-full">
                    <div className="flex gap-4">
                        <label className="input input-bordered focus-within:outline-none flex items-center justify-between gap-2 w-full">
                            <div className="flex items-center gap-2 w-full">
                                <AiOutlineSearch />
                                {imageUrl || imageFile ? (
                                    <div>
                                        {imageUrl && (
                                            <div className="flex items-center gap-2">
                                                <img
                                                    src={imageUrl}
                                                    alt="Added"
                                                    className="w-10 h-10 object-cover rounded"
                                                />
                                                <p className="text-xs truncate max-w-24">
                                                    {imageUrl}
                                                </p>
                                                <button
                                                    className="p-1 bg-gray-300 rounded-full"
                                                    onClick={handleImageRemove}
                                                >
                                                    <AiOutlineClose size={16} color="white" />
                                                </button>
                                            </div>
                                        )}
                                        {imageFile && (
                                            <div className="flex items-center gap-2 max-w-48">
                                                <img
                                                    src={URL.createObjectURL(imageFile)}
                                                    alt="Added"
                                                    className="w-10 h-10 object-cover rounded"
                                                />

                                                <p className="text-xs text-ellipsis">
                                                    {imageFile.name}
                                                </p>
                                                <button
                                                    className="p-1 bg-gray-300 rounded-full"
                                                    onClick={handleImageRemove}
                                                >
                                                    <AiOutlineClose size={16} color="black" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <input
                                        type="text"
                                        className="grow"
                                        placeholder="What are you looking for?"
                                        onChange={e => setSearchQuery(e.target.value)}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                handleSearch();
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            <div className="tooltip" data-tip="Search by reference image">
                                <button
                                    className="btn btn-ghost rounded-full btn-sm ml-auto"
                                    onClick={handleAddImageModalShow}
                                    disabled={!!(imageUrl || imageFile)}
                                >
                                    <MdImageSearch size={20} />
                                </button>
                            </div>
                        </label>

                        <div></div>

                        <button className="btn btn-primary text-white w-48" onClick={handleSearch}>
                            Search
                        </button>
                    </div>

                    {searchResults && (
                        <div className="flex gap-2 items-center mt-4">
                            <p className="font-medium">Search Results</p>
                            <div className="h-1 w-1 bg-gray-500 rounded-full"></div>
                            <p className="text-gray-500">{searchResults.matches.length} matches</p>
                        </div>
                    )}

                    <div className="text-left w-full mt-20">{renderResults()}</div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={handleModalShow}
                title={selectedVideo?.video_name || "Video Details"}
            >
                <div className="flex flex-col gap-4">
                    {selectedVideo && searchResults && (
                        <SearchVideoPlayer
                            url={selectedVideo.stream_url!}
                            start={selectedVideo.start_time}
                            end={selectedVideo.end_time}
                            subtitleUrl={selectedVideo.subtitle_url}
                        />
                    )}

                    <div>
                        <p>
                            <strong>Score: </strong>
                            {selectedVideo?.score.toFixed(3)}
                        </p>
                        <p>
                            <strong>Start Time: </strong>
                            {selectedVideo?.start_time.toFixed(3)} (
                            {parseDuration(selectedVideo?.start_time)})
                        </p>
                        <p>
                            <strong>End Time: </strong>
                            {selectedVideo?.end_time.toFixed(3)} (
                            {parseDuration(selectedVideo?.end_time)})
                        </p>

                        <p>
                            <strong>Match Types: </strong>
                            {selectedVideo?.types
                                .map(type => (type === "ocr" ? "text_in_video" : type))
                                .join(", ")}
                        </p>

                        {selectedVideo?.text && (
                            <p>
                                <strong>Text: </strong>
                                {selectedVideo?.text}
                            </p>
                        )}
                        <div className="flex justify-end gap-4 mt-4">
                            <button
                                className="btn btn-ghost "
                                onClick={() => {
                                    handleDownload(
                                        selectedVideo?.start_time,
                                        selectedVideo?.end_time,
                                        selectedVideo?.video_id
                                    );
                                }}
                            >
                                <AiOutlineDownload size={20} />
                                Download Clip
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isDefaultVideoModalOpen}
                onClose={handleDefaultVideoModalShow}
                title={defaultSelectedVideo?.file_name || "Video Details"}
            >
                <div className="flex flex-col gap-4">
                    {defaultSelectedVideo && defaultSelectedVideo.stream_url && (
                        <ReactPlayer
                            url={defaultSelectedVideo.stream_url}
                            controls
                            width="100%"
                            height="100%"
                            playing
                            config={{
                                file: {
                                    attributes: {
                                        crossOrigin: "anonymous",
                                    },
                                    tracks: defaultSelectedVideo.subtitle_url
                                        ? [
                                              {
                                                  kind: "subtitles",
                                                  src: defaultSelectedVideo.subtitle_url,
                                                  srcLang: "en",
                                                  label: "English",
                                                  default: true,
                                              },
                                          ]
                                        : [],
                                },
                            }}
                        />
                    )}
                </div>
            </Modal>

            <Modal isOpen={isAddImageModalOpen} onClose={handleAddImageModalShow} title="Add Image">
                <div className="flex flex-col gap-4">
                    <label className="form-control">
                        <span className="label-text">Image URL</span>
                        <div className="flex items-center gap-2">
                            <input
                                type="text"
                                className="input input-bordered flex-grow focus:outline-none"
                                value={imageUrl}
                                onChange={handleImageUrlChange}
                                disabled={!!imageFile}
                            />
                            <button
                                className="btn btn-primary text-white"
                                onClick={() => {
                                    if (imageUrl) {
                                        handleAddImageModalShow();
                                    }
                                }}
                                disabled={!imageUrl}
                            >
                                Add Image
                            </button>
                        </div>
                        {imageUrl && (
                            <div className="mt-2">
                                <img
                                    src={imageUrl}
                                    alt="Preview"
                                    className="w-full object-cover rounded"
                                />
                            </div>
                        )}
                    </label>
                    <label className="form-control cursor-pointer p-4 border-dashed border-2 border-gray-300 rounded-lg text-center">
                        <span className="label-text">Click or Drag to Upload Image</span>
                        <input
                            type="file"
                            className="hidden"
                            onChange={event => {
                                handleImageFileChange(event);
                                handleAddImageModalShow(); // Auto-dismiss modal
                            }}
                            accept="image/*"
                            disabled={!!imageUrl}
                        />
                    </label>
                </div>
            </Modal>

            <Toast
                icon={<AiOutlineCheckCircle size={20} color="green" />}
                message="Download job created. Please be on the look out for the notification up top!"
                show={showDownloadToast}
                onClose={() => setShowDownloadToast(false)}
            />
        </div>
    );
};

export default Search;
