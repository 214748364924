import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";

export interface Word {
    start: number;
    end: number;
    text: string;
}

export interface TranscriptProps {
    words: Word[];
    currentTime: number;
    onWordClick: (time: number) => void;
}

const parseVttTimestamp = (timestamp: string): number => {
    const [hours, minutes, seconds] = timestamp.split(":");
    const [secs, ms] = seconds.split(".");
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseFloat(`${secs}.${ms}`);
};

export const fetchSubtitles = async (subtitleUrl: string): Promise<Word[]> => {
    if (!subtitleUrl) return [];

    try {
        const response = await axios.get(subtitleUrl);
        const vttText = response.data.trim();
        const vttLines = vttText.split("\n\n").slice(1);

        const parsedWords = vttLines
            .map((vttLine: string): Word | null => {
                const [timeLine, text] = vttLine.split("\n").slice(1);
                if (!timeLine || !text) return null;

                const [startTimestamp, endTimestamp] = timeLine.split(" --> ");
                return {
                    start: parseVttTimestamp(startTimestamp),
                    end: parseVttTimestamp(endTimestamp),
                    text: text.trim(),
                };
            })
            .filter((word: Word | null): word is Word => word !== null);

        return parsedWords;
    } catch (error) {
        console.error("Error fetching or parsing subtitles", error);
        return [];
    }
};

const groupWordsBySilentGapsAndWordCount = (
    words: Word[],
    gapThreshold: number,
    maxWords: number
): Word[][] => {
    const paragraphs: Word[][] = [];
    let currentParagraph: Word[] = [];
    let wordCount = 0;

    words.forEach((word, index) => {
        currentParagraph.push(word);
        wordCount++;

        const isEndOfSentence = /[.!?]$/.test(word.text.trim());
        const isLastWord = index === words.length - 1;
        const nextWord = words[index + 1];
        const gap = nextWord ? nextWord.start - word.end : 0;

        if (gap > gapThreshold || (wordCount >= maxWords && isEndOfSentence) || isLastWord) {
            paragraphs.push(currentParagraph);
            currentParagraph = [];
            wordCount = 0;
        }
    });

    return paragraphs;
};

const Transcript: React.FC<TranscriptProps> = ({ words, currentTime, onWordClick }) => {
    const transcriptRef = useRef<HTMLDivElement>(null);
    const currentWordRef = useRef<HTMLSpanElement>(null);
    const [showJumpButton, setShowJumpButton] = useState(false);
    const isUserScrolling = useRef(false);
    const lastInteractionTime = useRef<number>(0);

    const getCurrentWord = useCallback(() => {
        return words.find(word => currentTime >= word.start && currentTime <= word.end);
    }, [words, currentTime]);

    const isCurrentWordVisible = useCallback(() => {
        if (!transcriptRef.current || !currentWordRef.current) return true;

        const containerRect = transcriptRef.current.getBoundingClientRect();
        const wordRect = currentWordRef.current.getBoundingClientRect();
        const buffer = 50;

        return (
            wordRect.top >= containerRect.top - buffer &&
            wordRect.bottom <= containerRect.bottom + buffer
        );
    }, []);

    const scrollToCurrentWord = useCallback((immediate = false) => {
        if (!transcriptRef.current || !currentWordRef.current) return;

        const containerRect = transcriptRef.current.getBoundingClientRect();
        const wordRect = currentWordRef.current.getBoundingClientRect();
        const containerHeight = transcriptRef.current.clientHeight;

        const idealScrollTop =
            transcriptRef.current.scrollTop +
            (wordRect.top - containerRect.top) -
            containerHeight / 2 +
            wordRect.height / 2;

        transcriptRef.current.scrollTo({
            top: idealScrollTop,
            behavior: immediate ? "auto" : "smooth",
        });
    }, []);

    const handleManualScroll = useCallback(() => {
        const now = Date.now();
        if (now - lastInteractionTime.current < 50) {
            isUserScrolling.current = true;
            setShowJumpButton(!isCurrentWordVisible());
        }
    }, [isCurrentWordVisible]);

    const handleUserInteraction = useCallback(() => {
        lastInteractionTime.current = Date.now();
    }, []);

    const handleJumpToCurrent = useCallback(() => {
        isUserScrolling.current = false;
        setShowJumpButton(false);
        scrollToCurrentWord(true);
    }, [scrollToCurrentWord]);

    useEffect(() => {
        if (!isUserScrolling.current && getCurrentWord()) {
            scrollToCurrentWord(false);
        }
    }, [currentTime, getCurrentWord, scrollToCurrentWord]);

    const paragraphs = groupWordsBySilentGapsAndWordCount(words, 1.0, 70);

    return (
        <div className="flex flex-col bg-gray-100 p-4 rounded-md shadow-lg h-full relative">
            <div className="text-xl font-semibold mb-4">Transcription</div>
            <div
                ref={transcriptRef}
                className="flex-grow overflow-y-auto overflow-x-hidden scroll-smooth relative"
                onScroll={handleManualScroll}
                onWheel={handleUserInteraction}
                onTouchStart={handleUserInteraction}
                onMouseDown={handleUserInteraction}
            >
                {paragraphs.map((paragraph, pIndex) => (
                    <p key={pIndex} className="leading-relaxed mb-8 break-words">
                        {paragraph.map((word, index) => (
                            <span
                                key={`${pIndex}-${index}`}
                                ref={
                                    currentTime >= word.start && currentTime <= word.end
                                        ? currentWordRef
                                        : null
                                }
                                className={`inline-block cursor-pointer transition-all duration-75 ease-in-out px-0.5 py-0.5 rounded hover:bg-blue-100 ${
                                    currentTime >= word.start && currentTime <= word.end
                                        ? "bg-blue-500 text-white font-medium shadow-sm"
                                        : ""
                                }`}
                                onClick={() => onWordClick(word.start)}
                            >
                                {word.text}
                            </span>
                        ))}
                    </p>
                ))}
                {showJumpButton && (
                    <div className="sticky bottom-4 w-full flex justify-center">
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-full shadow-lg flex items-center gap-2 transition-all duration-200"
                            onClick={handleJumpToCurrent}
                        >
                            <span>Jump to current word</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Transcript;
