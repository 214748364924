import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1.0,
});

const isDevelopment = process.env.NODE_ENV === 'development';

if (!isDevelopment) {
    amplitude.add(sessionReplayTracking);
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
}

const logEvent = (event, properties = {}) => {
    if (!isDevelopment) {
        amplitude.logEvent(event, properties);
    }
};

export default logEvent;
