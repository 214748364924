import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logEvent from "../../amplitude";
import {
    HiOutlineMagnifyingGlass,
    HiOutlineCheck,
    HiOutlineDocumentText,
    HiOutlineVideoCamera,
} from "react-icons/hi2";

import GridBackground from "../../components/Welcome/GridBackground";
import Footer from "../../components/Footer";

const DEMO_VIDEOS = {
    searchDemo: "https://dsebqouejkpw6.cloudfront.net/Demo/INTRO_DEMO.mp4",
    naturalLanguageSearch: "https://dsebqouejkpw6.cloudfront.net/Demo/VIDEO_SEARCH/NATURAL_LANGUAGE.mp4",
    multimodalSearch: "https://dsebqouejkpw6.cloudfront.net/Demo/VIDEO_SEARCH/MULTIMODAL_DEMO.mp4",
    crossLanguageSearch: "https://dsebqouejkpw6.cloudfront.net/Demo/VIDEO_SEARCH/MULTILINGUAL_DEMO.mp4"
};

const CORE_FEATURES = [
    {
        icon: HiOutlineMagnifyingGlass,
        title: "Natural Language Search",
        description: "Search across video content using everyday language - from specific actions to complex scenarios.",
        video: DEMO_VIDEOS.naturalLanguageSearch,
        color: "purple"
    },
    {
        icon: HiOutlineVideoCamera,
        title: "Multimodal Understanding",
        description: "Search through visual content, spoken words, and on-screen text simultaneously for comprehensive results.",
        video: DEMO_VIDEOS.multimodalSearch,
        color: "blue"
    },
    {
        icon: HiOutlineDocumentText,
        title: "Cross-Language Intelligence",
        description: "Break language barriers with support for 100+ languages and automatic translation capabilities.",
        video: DEMO_VIDEOS.crossLanguageSearch,
        color: "green"
    }
];

const USE_CASES = [
    {
        title: "Content Creation",
        description: "Perfect for video editors, producers, and content teams who need to quickly find specific clips",
        examples: [
            "Find all product demonstration clips",
            "Search for specific interview segments",
            "Locate scenes with particular visual elements"
        ]
    },
    {
        title: "Education & Training",
        description: "Make educational content searchable and accessible for students and trainers",
        examples: [
            "Search through lecture recordings",
            "Find specific topics in training videos",
            "Locate step-by-step demonstrations"
        ]
    },
    {
        title: "Media Management",
        description: "Organize and search through vast video libraries efficiently",
        examples: [
            "Archive management",
            "Content categorization",
            "Quick content retrieval"
        ]
    }
];

const PERFORMANCE_STATS = [
    {
        stat: "10x",
        label: "faster searches",
        description: "Compared to manual searching"
    },
    {
        stat: "100+",
        label: "supported languages",
        description: "Global search capabilities"
    },
    {
        stat: "99%",
        label: "accuracy rate",
        description: "High-precision results"
    },
    {
        stat: "<15min",
        label: "indexing per hour",
        description: "Quick processing time"
    }
];

const QuickSearch = () => {
    const navigate = useNavigate();

    useEffect(() => {
        logEvent("page_view", {
            page_title: "QuickSearch",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <div className="relative overflow-hidden bg-gradient-to-b from-white to-gray-50 -mt-9">
                <GridBackground gridOpacity={0.5} gridSize={40} gridColor="#CBD5E1" />
                <div className="container mx-auto px-6 pt-32 pb-24 relative">
                    <div className="max-w-4xl mx-auto text-center">
                        <h1 className="text-5xl md:text-7xl font-bold leading-tight mb-8">
                            Find Any Moment,{" "}
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
                                When You Need It
                            </span>
                        </h1>

                        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-12">
                            Transform petabytes of video content into searchable knowledge. Our AI understands context, 
                            actions, and speech across any video format.
                        </p>

                        <div className="mt-16 bg-white rounded-xl shadow-xl overflow-hidden border border-purple-100">
                            <div className="p-6 border-b border-gray-100">
                                <div className="text-left space-y-3">
                                    <h3 className="font-medium text-gray-900">See it in action</h3>
                                    <p className="text-gray-600">
                                        Watch how easy it is to find exact moments in your videos using natural language
                                    </p>
                                </div>
                            </div>
                            <div className="aspect-video bg-gray-50">
                                <video
                                    src={DEMO_VIDEOS.searchDemo}
                                    className="w-full h-full object-cover"
                                    controls
                                    autoPlay
                                    muted
                                    loop
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-24 bg-gray-50">
                <div className="container mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                            Beyond Traditional Video Search
                        </h2>
                        <p className="text-xl text-gray-600">
                            Our AI understands your videos like a human would
                        </p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        {CORE_FEATURES.map((feature, index) => (
                            <div key={index} className="bg-white p-8 rounded-xl shadow-lg">
                                <div className={`w-12 h-12 bg-${feature.color}-50 rounded-xl flex items-center justify-center mb-6`}>
                                    <feature.icon className={`w-6 h-6 text-${feature.color}-600`} />
                                </div>
                                <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                                <p className="text-gray-600 mb-6">{feature.description}</p>
                                <video
                                    src={feature.video}
                                    className="w-full rounded-lg"
                                    autoPlay
                                    muted
                                    loop
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="py-24">
                <div className="container mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                            Transforming Video Workflows
                        </h2>
                        <p className="text-xl text-gray-600">
                            See how teams are using our video search to work smarter
                        </p>
                    </div>

                    <div className="grid md:grid-cols-3 gap-8">
                        {USE_CASES.map((useCase, index) => (
                            <div key={index} className="bg-white p-8 rounded-xl shadow-lg border border-gray-100">
                                <h3 className="text-xl font-bold mb-4">{useCase.title}</h3>
                                <p className="text-gray-600 mb-6">{useCase.description}</p>
                                <ul className="space-y-3">
                                    {useCase.examples.map((example, i) => (
                                        <li key={i} className="flex items-center gap-2 text-sm text-gray-600">
                                            <HiOutlineCheck className="w-4 h-4 text-green-500" />
                                            {example}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 py-24">
                <div className="container mx-auto px-6">
                    <div className="grid md:grid-cols-4 gap-8">
                        {PERFORMANCE_STATS.map((item, index) => (
                            <div key={index} className="bg-white p-8 rounded-xl text-center">
                                <div className="text-4xl font-bold text-purple-600 mb-2">{item.stat}</div>
                                <div className="text-gray-900 font-medium mb-2">{item.label}</div>
                                <div className="text-sm text-gray-600">{item.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-6 py-24 text-center">
                <h2 className="text-4xl font-bold mb-8">
                    Ready to Transform Your Video Search?
                </h2>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                    <button
                        className="px-8 py-4 bg-primary text-white rounded-xl font-medium hover:opacity-90 transition-opacity shadow-lg shadow-purple-500/20"
                        onClick={() => navigate("/signup")}
                    >
                        Start Free Trial
                    </button>
                    <button className="px-8 py-4 bg-white rounded-xl font-medium text-gray-700 hover:bg-gray-50 transition-colors border border-gray-200 shadow-sm">
                        Schedule Demo
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default QuickSearch;

