import moment from "moment";

export const parseDateTime = (date: string) => {
    // Example date: 2024-03-17T00:31:34.628Z
    const dateTime = moment(date);
    const formattedDateTime = dateTime.format("MMMM D, YYYY");
    return formattedDateTime;
};

export const formatDate = (date: string) => {
    const dateTime = moment(date);
    const formattedDateTime = dateTime.format(" h:mm A, MMMM D");
    return formattedDateTime;
};

export const formatRelativeTime = (date: Date): string => {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const hours = Math.floor(diffInSeconds / 3600);
    const days = Math.floor(diffInSeconds / 86400);

    if (days >= 1) {
        return `${days}d`;
    }

    if (hours >= 1) {
        return `${hours}h`;
    }

    return "just now";
};
