import React, { useState, useEffect } from "react";
import blank from "../../assets/blank.jpg";
import { parseDuration } from "../utils/duration";
import { AiOutlineMore, AiFillDelete, AiOutlineMessage } from "react-icons/ai";
import { MdOutlineError, MdOutlineRateReview } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import { MdSubtitles } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";

export interface Video {
    id: string;
    user_id: number;
    file_name: string;
    file_size: string;
    status: string;
    status_percentage: number;
    file_url: string;
    video_duration: number;
    video_resolution: string;
    upload_date: string;
    stream_url?: string;
    subtitle_url: string;
    thumbnail_url?: string;
    error: string;
}

interface Props {
    video: Video;
    handleVideoSelect: (video: Video) => void;
    handleQuickReview?: (video: Video) => void;
    handleSubtitleDownload: (subtitle_url: string) => void;
    handleVideoDownload: (file_url: string) => void;
    handleVideoDelete: (video: Video) => void;
    handleRetry?: (video: Video) => void;
}

const DefaultVideoTile: React.FC<Props> = ({
    video,
    handleVideoSelect,
    handleQuickReview,
    handleSubtitleDownload,
    handleVideoDownload,
    handleVideoDelete,
    handleRetry,
}) => {
    const [thumbnailUrl, setThumbnailUrl] = useState(video.thumbnail_url);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        setThumbnailUrl(video.thumbnail_url);
    }, [video.thumbnail_url]);

    const isErrorState =
        video.status === "FAILED" || video.status === "OVER_LIMIT" || video.status === "ERROR";

    const handleTileClick = (e: React.MouseEvent) => {
        // Prevent click if the dropdown or its menu was clicked
        if ((e.target as HTMLElement).closest(".dropdown")) {
            return;
        }

        if (isErrorState && handleRetry) {
            handleRetry(video);
        } else if (video.status === "SUCCESS" || video.status === "VALIDATING") {
            handleVideoSelect(video);
        }
    };

    return (
        <div className="relative">
            <div className="relative w-64 h-36 cursor-pointer" onClick={handleTileClick}>
                <img
                    src={thumbnailUrl ? thumbnailUrl : blank}
                    className="w-full h-full object-cover rounded-md"
                    style={{
                        filter: video.status !== "SUCCESS" ? "brightness(50%)" : "none",
                    }}
                    onError={e => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = blank;
                    }}
                    alt={video.file_name}
                    loading="lazy"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                    {isErrorState && (
                        <div className="flex flex-col items-center tooltip" data-tip={video.error}>
                            <MdOutlineError className="text-red-500 text-3xl" />
                            <p className="text-red-500">{video.status}</p>
                            {handleRetry && (
                                <div className="flex items-center gap-2 mt-1">
                                    <BiRefresh className="text-red-500" />
                                    <p className="text-red-500 text-sm">Click to retry</p>
                                </div>
                            )}
                        </div>
                    )}

                    {(  video.status === "PENDING" ||
                        video.status === "VALIDATING" ||
                        video.status === "QUEUED" ||
                        video.status === "DELETING") && (
                        <div className="flex flex-col items-center">
                            <span
                                className={`loading loading-spinner loading-lg ${video.status === "DELETING" ? "text-red-500" : "text-white"}`}
                            ></span>
                            <p
                                className={`${video.status === "DELETING" ? "text-red-500" : "text-white"} mt-2 text-sm`}
                            >
                                {video.status.toLowerCase()}
                            </p>
                        </div>
                    )}

                    {video.status.includes("PROCESSING") && (
                        <div>
                            <div
                                className="radial-progress text-white"
                                style={{
                                    // @ts-ignore
                                    "--value": video.status_percentage,
                                    "--thickness": "4px",
                                }}
                                role="progressbar"
                            >
                                <p>{video.status_percentage}%</p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="absolute bottom-1 right-1 bg-black bg-opacity-70 text-white text-xs px-2 py-1 rounded">
                    <p>{parseDuration(video.video_duration)}</p>
                </div>
            </div>
            <div className="flex items-center mt-2">
                <p className="truncate text-sm w-60">{video.file_name}</p>
                <div className="dropdown dropdown-end">
                    <div
                        onClick={e => {
                            e.stopPropagation();
                            setIsDropdownOpen(!isDropdownOpen);
                        }}
                    >
                        <AiOutlineMore
                            tabIndex={0}
                            className="cursor-pointer hover:bg-slate-100 rounded-full p-1"
                            size={24}
                            color="black"
                        />
                    </div>
                    <ul
                        tabIndex={0}
                        className="dropdown-content menu p-2 shadow outline outline-gray-200 bg-base-100 rounded-box w-56 z-10"
                        onClick={e => {
                            e.stopPropagation();
                            setIsDropdownOpen(false);
                        }}
                    >
                        {isErrorState ? (
                            <>
                                {handleRetry && (
                                    <li>
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleRetry(video);
                                            }}
                                        >
                                            <BiRefresh size={16} color="black" />
                                            Retry Processing
                                        </button>
                                    </li>
                                )}
                                {video.user_id === parseInt(localStorage.getItem("user_id")!) && (
                                    <li>
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleVideoDelete(video);
                                            }}
                                        >
                                            <AiFillDelete
                                                className="inline"
                                                size={16}
                                                color="red"
                                            />
                                            Delete
                                        </button>
                                    </li>
                                )}
                            </>
                        ) : (
                            <>
                                {handleQuickReview &&
                                    video.user_id ===
                                        parseInt(localStorage.getItem("user_id")!) && (
                                        <li>
                                            <button
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleQuickReview(video);
                                                }}
                                            >
                                                <MdOutlineRateReview size={16} color="black" />
                                                Open in Quick Review
                                            </button>
                                        </li>
                                    )}
                                {video.subtitle_url !== "" && (
                                    <li>
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleSubtitleDownload(video.subtitle_url);
                                            }}
                                        >
                                            <MdSubtitles
                                                className="inline"
                                                size={16}
                                                color="black"
                                            />
                                            Download Transcript
                                        </button>
                                    </li>
                                )}
                                <li>
                                    <button
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleVideoDownload(video.file_url);
                                        }}
                                    >
                                        <IoMdDownload className="inline" size={16} color="black" />
                                        Download Video
                                    </button>
                                </li>
                                {video.user_id === parseInt(localStorage.getItem("user_id")!) && (
                                    <li>
                                        <button
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleVideoDelete(video);
                                            }}
                                        >
                                            <AiFillDelete
                                                className="inline"
                                                size={16}
                                                color="red"
                                            />
                                            Delete
                                        </button>
                                    </li>
                                )}
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DefaultVideoTile;
