import React from "react";
import { useNavigate } from "react-router-dom";
import {
    AiOutlineSearch,
    AiOutlineUpload,
    AiOutlineEye,
    AiOutlineAudio,
    AiOutlineFileText,
    AiOutlineVideoCamera,
} from "react-icons/ai";
import FilterMenu from "./FilterMenu";

interface ProcessingOptionProps {
    icon: React.ReactNode;
    label: string;
    tooltip: string;
}

interface CollectionHeaderProps {
    name: string;
    id: string;
    isCollectionOwner: boolean;
    onUpload: () => void;
    visual: boolean;
    audio: boolean;
    ocr: boolean;
    videosCount: number;
    totalDuration: string;
    filterQuery: string;
    onFilter: (query: string) => void;
    sortOptions: Array<{ label: string; value: string }>;
    selectedSort: string;
    onSortChange: (value: string) => void;
}

const ProcessingOption = React.memo<ProcessingOptionProps>(({ icon, label, tooltip }) => (
    <div className="relative group">
        <div className="flex items-center gap-2 px-3 py-1.5 text-gray-600 bg-gray-100 rounded-full text-sm hover:bg-gray-200 transition-colors duration-200 cursor-pointer">
            {icon}
            <span>{label}</span>
        </div>
        <div className="absolute z-10 invisible group-hover:visible bg-gray-800 text-white text-sm rounded p-2 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-64">
            {tooltip}
            <div className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 border-4 border-transparent border-t-gray-800"></div>
        </div>
    </div>
));

const CollectionHeader = React.memo<CollectionHeaderProps>(
    ({
        name,
        id,
        isCollectionOwner,
        onUpload,
        visual,
        audio,
        ocr,
        videosCount,
        totalDuration,
        filterQuery,
        onFilter,
        sortOptions,
        selectedSort,
        onSortChange,
    }) => {
        const navigate = useNavigate();

        const handleSearchClick = React.useCallback(() => {
            navigate(`/collections/${id}/search`);
        }, [navigate, id]);

        return (
            <div className="space-y-4">
                <div className="flex justify-center items-center mb-4">
                    <div className="flex flex-col justify-center w-full">
                        <h1 className="text-4xl font-bold">{name}</h1>
                    </div>

                    <div className="flex justify-end gap-5">
                        <button
                            className="btn bg-gray-100 hover:bg-gray-200 gap-2 px-4"
                            onClick={handleSearchClick}
                        >
                            <AiOutlineSearch size={20} />
                            <div className="flex flex-col items-start">
                                <span className="text-sm font-medium">Search Inside Videos</span>
                                <span className="text-xs text-gray-500">
                                    Find moments by content
                                </span>
                            </div>
                        </button>
                        {isCollectionOwner && (
                            <button className="btn btn-primary text-white" onClick={onUpload}>
                                <AiOutlineUpload size={18} />
                                Upload
                            </button>
                        )}
                    </div>
                </div>

                <div className="divider"></div>

                <div className="flex justify-between mb-5">
                    <div className="flex items-center gap-3">
                        <p className="text-gray-500 text-md">Modality:</p>
                        {visual && (
                            <ProcessingOption
                                icon={<AiOutlineEye size={16} />}
                                label="Visual"
                                tooltip="Search for visual elements like objects, actions, and scenes. Ideal for finding specific visual content or analyzing video imagery."
                            />
                        )}
                        {audio && (
                            <ProcessingOption
                                icon={<AiOutlineAudio size={16} />}
                                label="Conversation"
                                tooltip="Search through transcribed speech and dialogues. Perfect for finding specific mentions or analyzing conversations in videos."
                            />
                        )}
                        {ocr && (
                            <ProcessingOption
                                icon={<AiOutlineFileText size={16} />}
                                label="Text-in-video (OCR)"
                                tooltip="Search for text appearing in videos, such as subtitles, signs, or documents. Useful for finding specific written information within video content."
                            />
                        )}

                        <div className="divider divider-horizontal"></div>

                        <p className="flex items-center text-gray-500">
                            <AiOutlineVideoCamera className="mr-2" size={20} />
                            {videosCount} videos ({totalDuration})
                        </p>
                    </div>
                    <FilterMenu
                        onFilter={onFilter}
                        sortOptions={sortOptions}
                        selectedSort={selectedSort}
                        onSortChange={onSortChange}
                    />
                </div>
            </div>
        );
    }
);

export default CollectionHeader;
